import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Ionicons } from '@expo/vector-icons';
import {
  LessonBlock,
  LessonBlockList,
  ListItem,
  ListType,
} from '../../types/Types';
import { Text, View } from '../Themed';

export const ListBlockView: React.FunctionComponent<{ block: LessonBlock }> = (
  props
) => {
  if (!props.block.list) {
    return OLD_ListBlockView(props);
  }

  return (
    <View>
      {props.block.headline && (
        <Text style={[AppStyles.defaultMarginBottom, AppStyles.h2]}>
          {props.block.headline}
        </Text>
      )}
      <ListBlockSublistView list={props.block.list} />
    </View>
  );
};

const ListBlockSublistView: React.FC<{ list: LessonBlockList }> = (props) => {
  return (
    <View>
      {props.list.children.map((child, index) => {
        return (
          <View key={child.id}>
            <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
              {props.list.type === ListType.bullets && (
                <View style={AppStyles.listBullet} />
              )}
              {props.list.type === ListType.numbers && (
                <Text>{index + 1}. </Text>
              )}
              {props.list.type === ListType.checkboxes && child.meta?.done && (
                <View style={AppStyles.emptyCheckbox}>
                  <Ionicons name={'ios-checkmark-outline'} size={20} />
                </View>
              )}
              {props.list.type === ListType.checkboxes &&
                child.meta?.done !== true && (
                  <View style={AppStyles.emptyCheckbox} />
                )}
              <Text style={AppStyles.defaultMarginLeft}>{child.title}</Text>
            </View>
            <View style={{ paddingLeft: 20 }}>
              {child.list ? <ListBlockSublistView list={child.list} /> : null}
            </View>
          </View>
        );
      })}
    </View>
  );
};

const OLD_ListBlockView: React.FunctionComponent<{ block: LessonBlock }> = (
  props
) => {
  const itemsToDisplay = (props.block.listItems ?? []).filter(
    (i) => i.title.length
  );

  return (
    <View>
      {props.block.headline && (
        <Text style={[AppStyles.defaultMarginBottom, AppStyles.h2]}>
          {props.block.headline}
        </Text>
      )}
      {itemsToDisplay.map((item, index) => (
        <OLD_ListItemView
          key={index}
          item={item}
          itemIndex={index}
          listType={props.block.listType ?? ListType.bullets}
        />
      ))}
    </View>
  );
};

export const OLD_ListItemView: React.FunctionComponent<{
  item: ListItem;
  listType: ListType;
  itemIndex: number;
}> = (props) => {
  return (
    <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
      {props.listType === ListType.bullets && (
        <View style={AppStyles.listBullet} />
      )}
      {props.listType === ListType.numbers && (
        <Text>{props.itemIndex + 1}. </Text>
      )}
      {props.listType === ListType.checkboxes && props.item.done && (
        <Ionicons name={'ios-checkbox-outline'} size={24} />
      )}
      {props.listType === ListType.checkboxes && !props.item.done && (
        <View style={AppStyles.emptyCheckbox} />
      )}
      <Text style={AppStyles.defaultMarginLeft}>{props.item.title}</Text>
    </View>
  );
};
