import * as React from 'react';
import { LessonBlock } from '../types/Types';
import { MGSBlockView } from './MGSCustomContent';
import { Text, View } from '../components/Themed';

export const CustomBlockView: React.FunctionComponent<{
  block: LessonBlock;
}> = (props) => {
  const { block } = props;

  if (!block.customData) {
    return null;
  }

  switch (block.customData.type) {
    case 'MGS':
      return <MGSBlockView block={block} />;
    default:
      return (
        <View>
          <Text>Unknown custom block type</Text>
        </View>
      );
  }
};
