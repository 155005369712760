import * as React from 'react';
import { AppStyles } from '../style/Styles';
import { getLessonName } from '../utility/lessonKeeperFunctions';
import { LessonModel, Student } from '../types/Types';
import { Text, View } from './Themed';

type LessonCellProps = {
  lesson: LessonModel;
  students: Student[];
};

export const LessonCell = (props: LessonCellProps) => {
  return (
    <View style={AppStyles.lessonCell}>
      <View style={AppStyles.lessonCellBullet} />
      <Text>{getLessonName(props.lesson, props.students)}</Text>
    </View>
  );
};
