import { Ionicons } from '@expo/vector-icons';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import {
  ActivityIndicator,
  ScrollView,
  Switch,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import EnhancedModal from 'react-native-modal';
import { FirebaseContext, PasteboardContext } from '../../AppState';
import { InlineAddBlockButton } from '../components/AddBlockButton';
import { LKButton } from '../components/basicComponents/LKButton';
import { TimePickerView } from '../components/basicComponents/TimePickerView';
import { editorForBlock, LessonBlockView } from '../components/BlockView';
import { CreateResponseView } from '../components/CreateResponseView';
import { ResponseView } from '../components/ResponseView';
import { Text, View } from '../components/Themed';
import { RootStackParamList } from '../navigation';
import { AppStyles } from '../style/Styles';
import {
  Instructor,
  LessonBlock,
  LessonBlockResponse,
  LessonModel,
  ResourceType,
  Student,
} from '../types/Types';
import {
  callFirebaseEmailLessonFunction,
  copyServerFileToNewLesson,
  deleteLessonFromShare,
  deleteResponse,
  deleteServerFile,
  generateLessonViewerLink,
  generateStudentPageLink,
  getFirebaseUploadRef,
  getUserStorageSize,
  hasSynced,
  retrieveResponses,
  uploadFileToLesson,
  uploadLessonToShare,
  uploadLessonToSync,
  UploadState,
} from '../utility/firebaseFunctions';
import { arrayMove } from '../utility/general';
import {
  blockTypeBasedOnFileExtension,
  generateModifiedDate,
  getLessonDateTime,
  getLessonDateTimeString,
  getLessonName,
  instructorForLesson,
  studentsForLesson,
  uuidv4,
} from '../utility/lessonKeeperFunctions';
import { useKeyPress } from '../utility/reactUtility';

type LessonScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'LessonScreen'
>;

type LessonScreenRouteProp = RouteProp<RootStackParamList, 'LessonScreen'>;

type LessonScreenProps = {
  navigation: LessonScreenNavigationProp;
  route: LessonScreenRouteProp;
};

export function LessonScreen(props: LessonScreenProps) {
  const fbContext = React.useContext(FirebaseContext);
  const pasteboardContext = React.useContext(PasteboardContext);

  const [lesson, setLesson] = React.useState<LessonModel>();
  const [students, setStudents] = React.useState<Array<Student>>([]);
  const [instructor, setInstructor] = React.useState<Instructor>();

  const [responses, setResponses] = React.useState<LessonBlockResponse[]>([]);

  const [previousLessons, setPreviousLessons] = React.useState<LessonModel[]>(
    []
  );
  const [showPreviousLesson, setShowPreviousLesson] = React.useState(false);
  const [previousLessonOffset, setPreviousLessonOffset] = React.useState(0);

  const [showTimePicker, setShowTimePicker] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);

  const [showLessonTitleModal, setShowLessonTitleModal] = React.useState(false);
  const [lessonTitleModalText, setLessonTitleModalText] = React.useState<
    string | undefined
  >();

  const [currentlyEditing, setCurrentlyEditing] = React.useState<LessonBlock>();
  const shareLinkTextInput = React.useRef<TextInput>(null);
  const shareStudentLinkTextInput = React.useRef<TextInput>(null);

  const [showBusyMessage, setShowBusyMessage] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string | undefined>();

  const [emailShareLoading, setEmailShareLoading] = React.useState(false);

  useKeyPress('Escape', () => {
    setCurrentlyEditing(undefined);
    setShowSettings(false);
    setShowTimePicker(false);
    setShowShareModal(false);
  });

  React.useEffect(() => {
    const foundLesson = fbContext.syncState.lessons.find(
      (i) => i.id === props.route.params.lessonID
    );
    if (!foundLesson) {
      return;
    }
    //set the lesson for the page
    setLesson(foundLesson);

    setLessonTitleModalText(foundLesson.lessonName);

    //set the title
    props.navigation.setOptions({ title: getLessonName(foundLesson, []) });

    //try to get the previous lessons?
    const studentID =
      foundLesson.lessonMeta.studentIDs.length &&
      foundLesson.lessonMeta.studentIDs[0];

    if (studentID) {
      const foundPreviousLessons = fbContext.syncState.lessons
        .filter((i) => i.id !== foundLesson.id)
        .filter(
          (i) => i.lessonMeta.lessonDate < foundLesson.lessonMeta.lessonDate
        )
        .filter((i) => i.lessonMeta.studentIDs.includes(studentID))
        .filter((i) => !i.deleted)
        .sort((a, b) => a.lessonMeta.lessonDate - b.lessonMeta.lessonDate)
        .reverse();
      setPreviousLessons(foundPreviousLessons);
    }
  }, [fbContext.syncState.lessons, props.route.params.lessonID]);

  React.useEffect(() => {
    if (!fbContext.login.selectedUserID) {
      console.log(`No selected id`);
      return;
    }

    console.log(
      `Going to get responses for ${fbContext.login.selectedUserID} -- lesson ${props.route.params.lessonID}`
    );
    const unsubscribe = retrieveResponses(
      fbContext.login.selectedUserID,
      props.route.params.lessonID,
      (newResponses) => {
        setResponses(newResponses);
      }
    );

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [fbContext.login.selectedUserID, props.route.params.lessonID]);

  React.useEffect(() => {
    if (!lesson) {
      return;
    }
    setStudents(studentsForLesson(lesson, fbContext.syncState.students));
  }, [lesson, fbContext.syncState.students]);

  React.useEffect(() => {
    if (!lesson) {
      return;
    }
    setInstructor(instructorForLesson(lesson, fbContext.syncState.instructors));
  }, [lesson, fbContext.syncState.instructors]);

  const { selectedUserID } = fbContext.login;

  if (!selectedUserID) {
    return null;
  }

  if (!hasSynced(fbContext)) {
    return (
      <ActivityIndicator
        size="large"
        animating={true}
        style={AppStyles.defaultMarginTop}
      />
    );
  }

  if (!lesson) {
    return <Text>The selected lesson can't be found...</Text>;
  }

  if (!instructor) {
    return <Text>The instructor can't be found...</Text>;
  }

  const intermediaryUploadLesson = async (updatedLesson: LessonModel) => {
    //setTimeout(() => {
    console.log('Uploading lesson...');
    await uploadLessonToShare(
      selectedUserID,
      updatedLesson,
      students,
      instructor
    );
    await uploadLessonToSync(selectedUserID, updatedLesson);
    //}, 2000);
    //console.log(updatedLesson);
  };

  const toggleDeleteLesson = async () => {
    if (!lesson.deleted) {
      await deleteLessonFromShare(selectedUserID, lesson.id);
    }
    await uploadLessonToSync(selectedUserID, {
      ...lesson,
      deleted: !(lesson.deleted ?? false),
    });
    if (!lesson.deleted) {
      props.navigation.pop();
    }
  };

  const commitBlockFunction = async (
    block: LessonBlock,
    toLesson: LessonModel = lesson
  ) => {
    let updatedLesson = { ...toLesson };
    let blockIndex = updatedLesson.blocks.findIndex((i) => i.id === block.id);
    if (blockIndex === -1) {
      console.error("Couldn't find block index: " + block.id);
      console.log(updatedLesson.blocks);
      return;
    }
    updatedLesson.blocks[blockIndex] = block;
    await intermediaryUploadLesson(updatedLesson);
  };

  const moveBlockFunction = async (originalIndex: number, shift: number) => {
    if (originalIndex == 0 && shift < 0) {
      return;
    }
    if (originalIndex == lesson.blocks.length - 1 && shift > 0) {
      return;
    }

    let updatedLesson = { ...lesson };
    updatedLesson.blocks = arrayMove(
      updatedLesson.blocks,
      originalIndex,
      originalIndex + shift
    );
    await intermediaryUploadLesson(updatedLesson);
  };

  const copyBlocksFunction = (blocks: Array<LessonBlock>) => {
    pasteboardContext.dispatch({
      type: 'setCopiedBlocks',
      blocks: blocks,
      fromLessonID: lesson.id,
      fromInstructorID: selectedUserID,
    });
  };

  //This comes from the block library, so it should be the same user
  const copyBlocksToCurrentLessonFunction = async (
    blocks: Array<LessonBlock>,
    fromLessonID: string,
    fromInstructorID: string
  ) => {
    console.log('In copyBlocksToCurrentLessonFunction');
    //doing it this way so that it changes the id, copies the resources
    const blocksToAdd = await Promise.all(
      blocks
        .filter((i) => i.deleted !== true)
        .map((block) => {
          return makeCopyOfBlockIncludingResources(
            block,
            fromLessonID,
            fromInstructorID,
            selectedUserID
          );
        })
    );
    addBlockFunction(blocksToAdd, false);
  };

  const pasteBlocksFunction = async () => {
    console.log('Paste blocks:');
    console.log(pasteboardContext.pasteboard.copiedBlocks);

    const { fromLessonID, fromInstructorID } = pasteboardContext.pasteboard;

    if (!fromLessonID || !fromInstructorID) {
      alert('Missing information');
      console.error('Missing info for paste', fromLessonID, fromInstructorID);
      console.error(pasteboardContext.pasteboard);
      return;
    }

    const blocksToAdd = await Promise.all(
      pasteboardContext.pasteboard.copiedBlocks
        .filter((i) => i.deleted !== true)
        .map((block) => {
          return makeCopyOfBlockIncludingResources(
            block,
            fromLessonID,
            fromInstructorID,
            selectedUserID
          );
        })
    );
    console.log('Going to add blocks', blocksToAdd);
    addBlockFunction(blocksToAdd, false);
  };

  //this should only be called in the event that block.resource?.documentStorageParams?.path is filled
  const copyBlockResources = async (
    block: LessonBlock,
    fromLessonID: string,
    fromInstructorID: string,
    newBlockID: string,
    destinationInstructorID: string,
    callback: (newFileName: string | undefined) => void,
    errorCallback: (error: string) => void
  ) => {
    if (!block.resource?.documentStorageParams?.path) {
      return callback(undefined);
    }

    console.log(
      'Going to have to get resource: ' +
        block.resource?.documentStorageParams.path
    );

    const fileParts = block.resource.documentStorageParams.path.split('.');

    //TODO: may need to listen to files to see when they get uploaded

    const newFileName = newBlockID + '.' + fileParts[fileParts.length - 1];

    await copyServerFileToNewLesson(
      fromInstructorID,
      fromLessonID,
      block.resource.documentStorageParams.path,
      lesson.id,
      newFileName,
      destinationInstructorID,
      (progress) => {},
      (state) => {
        if (state === UploadState.finished) {
          callback(newFileName);
        }
      },
      errorCallback
    );
  };

  //used by both pasteBlocks and copyBlockToCurrentLesson
  const makeCopyOfBlockIncludingResources = (
    block: LessonBlock,
    fromLessonID: string,
    fromInstructorID: string,
    destinationInstructorID: string
  ): Promise<LessonBlock> => {
    //should get a new id and a new modified date
    const newBlockID = uuidv4();
    //copy the resources...
    if (block.resource?.documentStorageParams?.path) {
      return new Promise<LessonBlock>((resolve, reject) => {
        copyBlockResources(
          block,
          fromLessonID,
          fromInstructorID,
          newBlockID,
          destinationInstructorID,
          (newFileName: string | undefined) => {
            const originalResource = block.resource;
            if (!originalResource || !newFileName) {
              console.log('Resolving...', block.id);
              resolve({
                ...block,
                id: newBlockID,
                modifiedDate: generateModifiedDate(),
              });
            }

            console.log(
              'Have copied block and returned a new filename: ' + newFileName
            );

            console.log('Resolving 2', block.id);
            resolve({
              ...block,
              id: newBlockID,
              modifiedDate: generateModifiedDate(),
              resource: {
                base: ResourceType.documentStorage,
                documentStorageParams: {
                  path: newFileName ?? '',
                },
              },
            });
          },
          (error) => {
            alert(error);
            console.log('Rejecting');
            reject(error);
          }
        )
          .then(() => {
            console.log('Copied block');
          })
          .catch((error) => console.error(error));
      });
    } else {
      return new Promise<LessonBlock>((resolve, reject) => {
        console.log('Resolving 3', block.id);
        resolve({
          ...block,
          id: newBlockID,
          modifiedDate: generateModifiedDate(),
        });
      });
    }
  };

  const deleteBlockFunction = async (block: LessonBlock) => {
    //delete the associated file
    if (block.resource?.documentStorageParams) {
      await deleteServerFile(
        getFirebaseUploadRef(
          selectedUserID,
          lesson.id,
          block.resource?.documentStorageParams.path
        )
      );
      const userStorageSize = await getUserStorageSize(selectedUserID);
      console.log('User storage after delete: ' + userStorageSize);
    }

    let updatedLesson = { ...lesson };
    updatedLesson.blocks = updatedLesson.blocks.map((item) => {
      if (item.id == block.id) {
        return { ...item, deleted: true };
      } else {
        return item;
      }
    });
    intermediaryUploadLesson(updatedLesson);
  };

  const addBlockFunction = (
    blocks: Array<LessonBlock>,
    showEditorAfterAdd: boolean
  ) => {
    const updatedLesson: LessonModel = {
      ...lesson,
      blocks: [...lesson.blocks, ...blocks],
    };
    intermediaryUploadLesson(updatedLesson);
    setLesson(updatedLesson);

    if (showEditorAfterAdd && blocks.length) {
      setCurrentlyEditing(blocks[0]);
      // props.navigation.push('BlockEditorScreen', {
      //   block: block,
      //   lessonID: lesson.id,
      //   commitBlockFunction: (newBlock: LessonBlock) => {
      //     //have to do it this way because otherwise `lesson` gets captured in commitBlockFunction
      //     //without having the new block added yet
      //     commitBlockFunction(newBlock, updatedLesson);
      //   },
      // });
    }
  };

  const deleteResponseFunction = (response: LessonBlockResponse) => {
    deleteResponse(response, selectedUserID, lesson.id);
  };

  const addFileFunction = async (file: File) => {
    const newBlockID = uuidv4();

    const fileExt = file.name.split('.').pop();
    const filename = `${newBlockID}.${fileExt}`;

    const type = blockTypeBasedOnFileExtension(fileExt ?? '');

    if (!type) {
      alert("I'm sorry, this file type isn't recognized by LessonKeeper");
      return;
    }

    setShowBusyMessage(true);

    await uploadFileToLesson(
      file,
      filename,
      selectedUserID,
      lesson.id,
      (progress) => {
        setBusyMessage(`Uploading ${file.name}: ${progress}%`);
        console.log(`Upload progress: ${progress}`);
      },
      (state) => {
        switch (state) {
          case UploadState.error:
            //the error callback below will get used
            return;
          case UploadState.finished:
            break;
          case UploadState.uploading:
            //TODO: show user status
            return;
        }

        //the file was uploaded -- add a block

        console.log('Finished with state: ' + state);
        setShowBusyMessage(false);

        addBlockFunction(
          [
            {
              id: newBlockID,
              type,
              textContent: '',
              modifiedDate: generateModifiedDate(),
              resource: {
                base: ResourceType.documentStorage,
                documentStorageParams: {
                  path: filename,
                },
              },
            },
          ],
          false
        );
      },
      (error) => {
        console.error('Error uploading file: ' + error);
        setShowBusyMessage(false);
        alert(error);
        //console.error(error);
      }
    );
  };

  const displayBlocksFromLesson = (
    lessonToDisplay: LessonModel,
    dimmed: boolean
  ) => {
    return lessonToDisplay.blocks
      .filter((i) => !i.deleted)
      .map((block, index) => {
        const blockResponses = responses
          .filter((response) => response.blockID === block.id)
          .sort((a, b) => a.modifiedDate.getTime() - b.modifiedDate.getTime());

        return (
          <View key={block.id} style={AppStyles.blockWrapper}>
            <LessonBlockView
              key={block.id}
              block={block}
              blockIndex={index}
              isLast={index === lesson.blocks.length - 1}
              lessonID={lessonToDisplay.id}
              instructorID={selectedUserID}
              moveBlockFunction={moveBlockFunction}
              commitBlockFunction={commitBlockFunction}
              copyBlocksFunction={copyBlocksFunction}
              copyBlocksToCurrentLessonFunction={
                copyBlocksToCurrentLessonFunction
              }
              deleteBlockFunction={deleteBlockFunction}
              setShowEditor={(show) => {
                setCurrentlyEditing(block);
                // props.navigation.push('BlockEditorScreen', {
                //   block,
                //   lessonID: lesson.id,
                //   commitBlockFunction,
                // });
              }}
              dimmed={dimmed}
            />
            {blockResponses.map((response) => (
              <ResponseView
                response={response}
                key={response.id}
                lessonID={lesson.id}
                instructorID={selectedUserID}
                deleteResponseFunction={deleteResponseFunction}
              />
            ))}
            {!dimmed && blockResponses.length ? (
              <CreateResponseView
                lessonOwnerID={selectedUserID}
                blockID={block.id}
                lessonID={lesson.id}
              />
            ) : null}
          </View>
        );
      });
  };

  const getPreviousLesson = () => {
    if (!showPreviousLesson) return;
    if (!previousLessons.length) return;
    return previousLessons[previousLessonOffset];
  };

  const previousLesson = getPreviousLesson();

  const allowResponses = () => {
    if (lesson.lessonMeta.displayOptions?.allowResponses === undefined) {
      return true;
    }
    return lesson.lessonMeta.displayOptions?.allowResponses;
  };

  const showInstructorEmail = () => {
    if (lesson.lessonMeta.displayOptions?.showInstructorEmail === false) {
      return false;
    }
    return true;
  };

  const settingsModal = (
    <EnhancedModal
      isVisible={showSettings}
      onBackdropPress={() => {
        setShowSettings(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Lesson settings
        </Text>

        <View
          style={[
            AppStyles.row,
            AppStyles.defaultMarginBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <Text>Allow responses</Text>
          <Switch
            value={allowResponses()}
            onValueChange={async (val) => {
              console.log('Setting value to: ' + val);
              let metaCopy = lesson.lessonMeta;
              if (!metaCopy.displayOptions) {
                metaCopy.displayOptions = {};
              }
              metaCopy.displayOptions.allowResponses = val;
              await intermediaryUploadLesson({
                ...lesson,
                lessonMeta: metaCopy,
              });
            }}
          />
        </View>

        <View
          style={[
            AppStyles.row,
            AppStyles.defaultMarginBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <Text>Show instructor email</Text>
          <Switch
            value={showInstructorEmail()}
            onValueChange={async (val) => {
              console.log('Setting value to: ' + val);
              let metaCopy = lesson.lessonMeta;
              if (!metaCopy.displayOptions) {
                metaCopy.displayOptions = {};
              }
              metaCopy.displayOptions.showInstructorEmail = val;
              await intermediaryUploadLesson({
                ...lesson,
                lessonMeta: metaCopy,
              });
            }}
          />
        </View>

        <View style={AppStyles.separator} />
        <LKButton
          title="Copy all blocks"
          iconName="md-copy"
          onPress={() => {
            copyBlocksFunction(lesson.blocks);
            window.alert(
              'The blocks have been copied to the LessonKeeper clipboard.'
            );
          }}
        />

        <View style={AppStyles.separator} />

        <LKButton
          title={lesson.deleted ? 'Undelete lesson' : 'Delete lesson'}
          iconName="ios-trash"
          destructive={true}
          onPress={async () => {
            if (
              window.confirm(
                lesson.deleted ? 'Undelete this lesson?' : 'Delete this lesson?'
              )
            ) {
              await toggleDeleteLesson();
            }
          }}
        />
      </View>
    </EnhancedModal>
  );

  const shareModal = (
    <EnhancedModal
      isVisible={showShareModal}
      onBackdropPress={() => {
        setShowShareModal(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>Share</Text>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text>Lesson: </Text>
          <TextInput
            value={generateLessonViewerLink(selectedUserID, lesson.id)}
            style={[
              AppStyles.basicTextInput,
              AppStyles.defaultMarginRight,
              { flex: 3 },
            ]}
            selectTextOnFocus={true}
            ref={shareLinkTextInput}
          />
          <LKButton
            title="Copy link"
            iconName="md-copy"
            onPress={() => {
              const url = generateLessonViewerLink(selectedUserID, lesson.id);
              console.log('Going to focus', shareLinkTextInput.current);
              shareLinkTextInput.current?.focus();
              setTimeout(() => {
                if (document.execCommand('copy')) {
                  alert('The link has been copied to your clipboard');
                }
              }, 50);
            }}
            style={[{ width: 150 }]}
          />
        </View>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text>Student page: </Text>
          <TextInput
            value={generateStudentPageLink(
              selectedUserID,
              lesson.lessonMeta.studentIDs[0]
            )}
            style={[
              AppStyles.basicTextInput,
              AppStyles.defaultMarginRight,
              { flex: 3 },
            ]}
            selectTextOnFocus={true}
            ref={shareStudentLinkTextInput}
          />
          <LKButton
            title="Copy link"
            iconName="md-copy"
            onPress={() => {
              const url = generateStudentPageLink(
                selectedUserID,
                lesson.lessonMeta.studentIDs[0]
              );
              shareStudentLinkTextInput.current?.focus();
              setTimeout(() => {
                if (document.execCommand('copy')) {
                  alert('The link has been copied to your clipboard');
                }
              }, 50);
            }}
            style={[{ width: 150 }]}
          />
        </View>
        {students.length && students[0].contactInfo.email ? (
          <LKButton
            title="Send to student email"
            iconName="ios-mail"
            loading={emailShareLoading}
            onPress={async () => {
              const student = students.length && students[0];
              if (!student) {
                return;
              }
              if (!student.contactInfo.email) {
                console.error('No student email');
                return;
              }
              setEmailShareLoading(true);
              const result = await callFirebaseEmailLessonFunction(
                selectedUserID,
                student.id,
                lesson.id,
                student.contactInfo.email
              );
              setEmailShareLoading(false);
              if (result.success) {
                alert(
                  'An email has been sent to the student with a link to the lesson'
                );
              } else {
                alert(
                  `There was an error sending the link to the student: ${result.userError}`
                );
              }
            }}
          />
        ) : null}

        <LKButton
          title="Open link"
          iconName="ios-share"
          onPress={() => {
            const url = generateLessonViewerLink(selectedUserID, lesson.id);
            if (url) {
              window.open(url, '_blank');
            }
          }}
        />
      </View>
    </EnhancedModal>
  );

  const editorModal = (
    <EnhancedModal
      isVisible={currentlyEditing != undefined}
      onBackdropPress={() => {
        setCurrentlyEditing(undefined);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, { flex: 1 }]}>
        <ScrollView style={AppStyles.screenScroller}>
          {currentlyEditing
            ? editorForBlock(
                currentlyEditing,
                lesson.id,
                commitBlockFunction,
                () => setCurrentlyEditing(undefined)
              )
            : null}
        </ScrollView>
      </View>
    </EnhancedModal>
  );

  const lessonTitleModal = (
    <EnhancedModal
      isVisible={showLessonTitleModal}
      onBackdropPress={() => {
        setShowLessonTitleModal(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Lesson title
        </Text>
        <TextInput
          style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
          value={lessonTitleModalText ?? ''}
          onChangeText={setLessonTitleModalText}
          //defaultValue={getLessonDateTimeString(lesson)}
        />
        <LKButton
          title="Save"
          onPress={async () => {
            if (lessonTitleModalText) {
              await intermediaryUploadLesson({
                ...lesson,
                lessonName: lessonTitleModalText,
              });
            } else {
              let updatedLesson: LessonModel = { ...lesson };
              delete updatedLesson.lessonName;
              await intermediaryUploadLesson(updatedLesson);
            }
            setShowLessonTitleModal(false);
          }}
        />
      </View>
    </EnhancedModal>
  );

  const timePickerModal = (
    <EnhancedModal
      isVisible={showTimePicker}
      onBackdropPress={() => {
        setShowTimePicker(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Lesson time
        </Text>
        <TimePickerView
          inputTime={getLessonDateTime(lesson)}
          showDate={true}
          showTime={true}
          callback={(date) => {
            const updatedLesson: LessonModel = {
              ...lesson,
              lessonMeta: {
                ...lesson.lessonMeta,
                lessonDate: date.getTime() / 1000,
              },
            };
            intermediaryUploadLesson(updatedLesson);
            setLesson(updatedLesson);
            setShowTimePicker(false);
          }}
        />
      </View>
    </EnhancedModal>
  );

  const busyModal = (
    <EnhancedModal
      isVisible={showBusyMessage}
      onBackdropPress={() => setShowBusyMessage(false)}
    >
      <View
        style={{ padding: 40, alignItems: 'center', justifyContent: 'center' }}
      >
        <ActivityIndicator
          size="small"
          animating={true}
          style={AppStyles.defaultMarginBottom}
        />
        <Text>{busyMessage}</Text>
      </View>
    </EnhancedModal>
  );

  //console.log('Rendering lesson page with context:');
  //console.log(fbContext);

  return (
    <View style={[AppStyles.container, { alignItems: 'stretch' }]}>
      <View
        style={
          AppStyles.container /* for some reason, I need this to make it scroll */
        }
      >
        {editorModal}
        {settingsModal}
        {lessonTitleModal}
        {timePickerModal}
        {shareModal}

        {busyModal}

        <ScrollView contentContainerStyle={AppStyles.lessonScroller}>
          <TouchableOpacity
            onPress={() => setShowLessonTitleModal(true)}
            style={AppStyles.defaultMarginBottom}
          >
            <Text style={[AppStyles.h2]}>
              {lesson && getLessonName(lesson, students)}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={AppStyles.row}
            onPress={() => setShowTimePicker(!showTimePicker)}
          >
            <Ionicons
              name="ios-calendar-outline"
              size={20}
              style={AppStyles.defaultMarginRight}
            />
            <Text>{getLessonDateTimeString(lesson)}</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={AppStyles.row}
            onPress={() => setShowShareModal(true)}
          >
            <Ionicons
              name="ios-share"
              size={20}
              style={AppStyles.defaultMarginRight}
            />
            <Text>Share lesson</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={AppStyles.row}
            onPress={() => setShowSettings(true)}
          >
            <Ionicons
              name="ios-settings"
              size={20}
              style={AppStyles.defaultMarginRight}
            />
            <Text>Settings</Text>
          </TouchableOpacity>

          {previousLessons.length ? (
            <TouchableOpacity
              onPress={() => {
                setShowPreviousLesson(!showPreviousLesson);
              }}
              style={AppStyles.row}
            >
              {!showPreviousLesson ? (
                <>
                  <Ionicons
                    name={'arrow-forward-circle-outline'}
                    size={20}
                    style={AppStyles.defaultMarginRight}
                  />
                  <Text>Show previous lesson</Text>
                </>
              ) : (
                <>
                  <Ionicons
                    name={'arrow-down-circle-outline'}
                    size={20}
                    style={AppStyles.defaultMarginRight}
                  />
                  <Text>Hide previous lesson</Text>
                </>
              )}
            </TouchableOpacity>
          ) : null}

          {previousLesson && (
            <View style={{ backgroundColor: 'clear' }}>
              <Text>
                Previous lesson {getLessonDateTimeString(previousLesson)}:
              </Text>
              <View
                style={[AppStyles.row, { justifyContent: 'space-between' }]}
              >
                {previousLessonOffset !== previousLessons.length - 1 ? (
                  <TouchableOpacity
                    onPress={() =>
                      setPreviousLessonOffset(previousLessonOffset + 1)
                    }
                    style={AppStyles.row}
                  >
                    <Ionicons
                      name={'ios-arrow-back'}
                      size={20}
                      style={AppStyles.defaultMarginRight}
                    />
                    <Text>Prev</Text>
                  </TouchableOpacity>
                ) : (
                  <View />
                )}
                {previousLessonOffset !== 0 && (
                  <TouchableOpacity
                    onPress={() =>
                      setPreviousLessonOffset(previousLessonOffset - 1)
                    }
                    style={AppStyles.row}
                  >
                    <Text>Next</Text>
                    <Ionicons
                      name={'ios-arrow-forward'}
                      size={20}
                      style={AppStyles.defaultMarginLeft}
                    />
                  </TouchableOpacity>
                )}
              </View>
              <View style={[AppStyles.row, { alignItems: 'stretch' }]}>
                <View style={AppStyles.previousLessonLeftBar} />
                <View style={{ flex: 1, backgroundColor: 'clear' }}>
                  {displayBlocksFromLesson(previousLesson, true)}
                </View>
              </View>
            </View>
          )}

          <View style={AppStyles.separator} />

          {showPreviousLesson && (
            <Text style={AppStyles.currentLessonLabel}>Current lesson:</Text>
          )}

          {displayBlocksFromLesson(lesson, false)}

          <View style={{ alignSelf: 'center', backgroundColor: 'clear' }}>
            <InlineAddBlockButton
              addBlockFunction={addBlockFunction}
              addFileFunction={addFileFunction}
              pasteBlocksFunction={pasteBlocksFunction}
              copyBlocksToCurrentLessonFunction={
                copyBlocksToCurrentLessonFunction
              }
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
