import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { FirebaseContext } from '../../AppState';
import { Text, View } from '../components/Themed';
import { MainScreenNavigationProp } from '../screens/MainScreen';
import { AppStyles } from '../style/Styles';
import { LessonModel, Student } from '../types/Types';
import { LessonCell } from './LessonCell';

const _StudentView: React.FunctionComponent<{
  student: Student;
  lessons: LessonModel[];
  addLesson: (forStudent: Student) => void;
}> = (props) => {
  const fbContext = React.useContext(FirebaseContext);
  const navigation = useNavigation<MainScreenNavigationProp>();

  const [expandLessons, setExpandLessons] = React.useState(false);

  const lessonsToDisplay = expandLessons ? props.lessons : []; //props.lessons.slice(-5);

  console.log('Render...');

  return (
    <View style={AppStyles.inlineStudentView}>
      <View style={AppStyles.row}>
        <TouchableOpacity
          onPress={() => {
            navigation.push('StudentEditorScreen', {
              studentID: props.student.id,
              selectedUserID: fbContext.login.selectedUserID,
            });
          }}
          style={AppStyles.row}
        >
          <Ionicons
            name="ios-person"
            size={22}
            style={AppStyles.defaultMarginRight}
          />
          <Text style={[AppStyles.h2, AppStyles.link]}>
            {props.student.firstName} {props.student.lastName}
          </Text>
        </TouchableOpacity>
      </View>

      {/* <View style={AppStyles.separator} /> */}
      {props.lessons.length ? (
        <TouchableOpacity
          style={[AppStyles.row, AppStyles.defaultMarginTop, { marginBottom: expandLessons ? 6 : 0 }]}
          onPress={() => setExpandLessons(!expandLessons)}
        >
          <Ionicons
            name={
              expandLessons
                ? 'arrow-down-circle-outline'
                : 'arrow-forward-circle-outline'
            }
            size={20}
            style={AppStyles.defaultMarginRight}
          />
          <Text>{expandLessons ? 'Hide lessons' : 'Show lessons'}</Text>
        </TouchableOpacity>
      ) : null}
      {lessonsToDisplay.map((lesson) => (
        <TouchableOpacity
          key={lesson.id}
          onPress={() => {
            navigation.push('LessonScreen', {
              lessonID: lesson.id,
              selectedUserID: fbContext.login.selectedUserID,
            });
          }}
        >
          <LessonCell lesson={lesson} students={[]} />
        </TouchableOpacity>
      ))}
      {expandLessons || props.lessons.length === 0 ? (
        <TouchableOpacity
          onPress={() => {
            console.log('Adding lesson for:', props.student, props.addLesson);
            props.addLesson(props.student);
          }}
          style={[AppStyles.row, { alignSelf: 'center', marginTop: 15 }]}
        >
          <Ionicons
            name="ios-add-circle"
            size={24}
            style={AppStyles.defaultMarginRight}
          />
          <Text>Add lesson</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export const StudentView = React.memo(_StudentView);
