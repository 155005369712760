import * as React from 'react';
import { LessonBlock } from '../types/Types';
import { Text, View } from '../components/Themed';
import {
  MGSBlockEditorView,
  MGSBlockType,
  MGSBlockView,
} from './MGSCustomContent';

export const CustomBlockEditor: React.FunctionComponent<{
  block: LessonBlock;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const { block } = props;

  if (!block.customData) {
    return null;
  }

  switch (block.customData.type) {
    case MGSBlockType:
      return (
        <MGSBlockEditorView
          block={block}
          commitBlockFunction={props.commitBlockFunction}
          finish={props.finish}
        />
      );
    default:
      return (
        <View>
          <Text>Unknown custom block type</Text>
        </View>
      );
  }
};
