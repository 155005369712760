import * as React from 'react';

export const LinkView: React.FunctionComponent<{
  url: string;
  caption?: string;
}> = (props) => {
  if (props.caption) {
    return (
      <a
        href={props.url}
        style={{ display: 'block', textAlign: 'center', color: '#34b575' }}
      >
        {props.caption}
      </a>
    );
  } else {
    const urlText =
      props.url.length > 80 ? props.url.slice(0, 80) + '...' : props.url;
    return (
      <a
        href={props.url}
        style={{
          display: 'block',
          textAlign: 'center',
          fontFamily: '-apple-system',
          color: '#34b575',
        }}
      >
        {urlText}
      </a>
    );
  }
};
