import * as React from 'react';
import EnhancedModal from 'react-native-modal';
import { AppStyles } from '../../style/Styles';
import { FirebaseContext } from '../../../AppState';
import { LKButton } from '../basicComponents/LKButton';
import { Text, View } from '../Themed';
import {
  Button,
  Image,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import {
  firebaseSignInWithApple,
  firebaseSignInWithGoogle,
  firebaseSignOut,
} from '../../utility/firebaseFunctions';

export const LoginLogoutView: React.FunctionComponent<{
  style: StyleProp<ViewStyle>;
}> = (props) => {
  const fbContext = React.useContext(FirebaseContext);

  return (
    <View style={props.style}>
      {fbContext.login.uid ? <LogoutView /> : <LoginView />}
    </View>
  );
};

export const LoginView: React.FunctionComponent<{
  showMustBeLoggedInMessage?: boolean;
}> = (props) => {
  const [showLoginModal, setShowLoginModal] = React.useState(false);

  const loginButton = (
    <LKButton
      title="Log in"
      onPress={() => {
        setShowLoginModal(true);
      }}
    />
  );

  const loginModal = (
    <EnhancedModal
      isVisible={showLoginModal}
      onBackdropPress={() => {
        setShowLoginModal(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View
        style={[
          AppStyles.basicModal,
          AppStyles.modalPadding,
          AppStyles.loginModal,
          AppStyles.bordered,
        ]}
      >
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Log in to LessonKeeper
        </Text>
        {props.showMustBeLoggedInMessage ? (
          <Text style={AppStyles.defaultMarginBottom}>
            You must be logged in to use this feature of LessonKeeper
          </Text>
        ) : null}

        <TouchableOpacity
          style={{ marginBottom: 10 }}
          onPress={() => firebaseSignInWithGoogle()}
        >
          <Image
            source={require('../../../assets/images/btn_google_signin_light_normal_web@2x.png')}
            style={[AppStyles.signInServiceButton]}
          />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => firebaseSignInWithApple()}>
          <Image
            source={require('../../../assets/images/appleid_button@2x.png')}
            style={[AppStyles.signInServiceButton]}
          />
        </TouchableOpacity>
      </View>
    </EnhancedModal>
  );

  return (
    <View>
      {loginModal}
      {loginButton}
    </View>
  );
};

export const LogoutView: React.FunctionComponent<{}> = (props) => {
  const logoutButton = (
    <LKButton
      title="Log out"
      onPress={() => {
        firebaseSignOut();
      }}
    />
  );

  return <View>{logoutButton}</View>;
};
