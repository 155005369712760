import * as React from 'react';
import { AppStyles } from '../style/Styles';
import { Button, ScrollView, TextInput } from 'react-native';
import { FirebaseContext } from '../../AppState';
import { Instructor } from '../types/Types';
import { RootStackParamList } from '../navigation';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Text, View } from '../components/Themed';
import { uploadInstructorToSync } from '../utility/firebaseFunctions';

type ScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'InstructorEditorScreen'
>;

type ScreenRouteProp = RouteProp<RootStackParamList, 'InstructorEditorScreen'>;

type MainScreenProps = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

export function InstructorEditorScreen(props: MainScreenProps) {
  const [instructor, setInstructor] = React.useState<Instructor>();

  const fbContext = React.useContext(FirebaseContext);

  React.useEffect(() => {
    const found = fbContext.syncState.instructors.find(
      (i) => i.id === props.route.params.instructorID
    );
    if (!found) {
      return;
    }
    setInstructor(found);
    props.navigation.setOptions({
      title: `${found.firstName} ${found.lastName}`,
    });
  }, [fbContext.syncState.instructors, props.route.params.instructorID]);

  if (!instructor) {
    return null;
  }

  props.navigation.addListener('beforeRemove', (event) => {
    //TODO: warn the user
    if (!isValid) {
      event.preventDefault();
      return;
    }
    modifyInstructor(instructor);
  });

  const isValid = (updated: Instructor) => {
    if (updated.firstName.length) {
      return true;
    }
    return false;
  };

  const modifyInstructor = (updated: Instructor) => {
    if (!isValid(updated)) {
      alert('Please enter a first name for the instructor');
      return;
    }
    if (!fbContext.login.selectedUserID) {
      return null;
    }
    uploadInstructorToSync(fbContext.login.selectedUserID, updated);
  };

  return (
    <View style={AppStyles.container}>
      <ScrollView style={AppStyles.screenScroller}>
        <Text style={[AppStyles.h2]}>
          Instructor: {instructor.firstName} {instructor.lastName}
        </Text>
        <View style={AppStyles.separator} />
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>First name:</Text>
          <TextInput
            value={instructor.firstName}
            style={AppStyles.basicTextInput}
            onChangeText={(text) =>
              setInstructor({ ...instructor, firstName: text })
            }
          />
        </View>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Last name:</Text>
          <TextInput
            value={instructor.lastName}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) =>
              setInstructor({ ...instructor, lastName: text })
            }
          />
        </View>
        <View style={AppStyles.separator} />
        <Text style={AppStyles.defaultMarginBottom}>Contact Info:</Text>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Phone:</Text>
          <TextInput
            value={instructor.contactInfo.phone}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) =>
              setInstructor({
                ...instructor,
                contactInfo: { ...instructor.contactInfo, phone: text },
              })
            }
          />
        </View>
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text style={AppStyles.defaultMarginRight}>Email:</Text>
          <TextInput
            value={instructor.contactInfo.email}
            style={[AppStyles.basicTextInput]}
            onChangeText={(text) =>
              setInstructor({
                ...instructor,
                contactInfo: { ...instructor.contactInfo, email: text },
              })
            }
          />
        </View>
        <View style={AppStyles.separator} />
        {/* <Text>Other info:</Text>
        <TextInput
          multiline={true}
          value={instructor.metaInfo}
          style={[AppStyles.basicTextInput, { height: 300 }]}
          onChangeText={(text) => setInstructor({ ...instructor, metaInfo: text })}
        /> */}
        <Button title="Save" onPress={() => modifyInstructor(instructor)} />
      </ScrollView>
    </View>
  );
}
