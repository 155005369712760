export interface FirebaseSyncState {
  lessons: LessonModel[];
  students: Student[];
  instructors: Instructor[];

  sync: {
    hasSyncedLessons: boolean;
    hasSyncedStudents: boolean;
    hasSyncedInstructors: boolean;
  };
}

export type AdministratedUser = { id: string; role: string };

export type FirebaseState = {
  login: {
    uid?: string;
    selectedUserID?: string; //mostly going to use this for admin
    userGroup: {
      synced: boolean;
      group?: string;
      role?: string;
      isAdmin?: boolean;
      usersToAdministrate?: AdministratedUser[];
    };
  };
  syncState: FirebaseSyncState;
  actions: {
    refreshUserGroup: () => void;
    selectUserID: (uid: string) => void;
  };
};

export interface LessonModel {
  id: string;
  blocks: LessonBlock[];
  lessonName?: string;
  lessonAmount?: number;
  lessonMeta: {
    instructorID: string;
    lessonDate: number;
    studentIDs: string[];
    groupID?: string;

    displayOptions?: {
      allowResponses?: boolean;
      allowLiveUpdates?: boolean;
      showInstructorEmail?: boolean;
    };
  };
  payInfo: PayInfo;
  type: LessonType;
  modifiedDate: number;
  deleted?: boolean;
}

export interface PayInfo {
  amount: number;
  method: {
    base: string;
  };
}

// export interface PayInfo {
//   method: PayInfoMethod,
//   amount: number,
//   paidDate?: number;
// }

// export interface PayInfoMethod {
//   base: 'cash' | 'check' | 'card' | 'online' | 'other' | 'unpaid'
// }

export interface LessonType {
  base: "private" | "school" | "group" | "other";
  otherParams?: {
    type: string;
  };
}

export enum BlockType {
  audio = "audio",
  pdf = "pdf",
  text = "text",
  list = "list",
  link = "link",
  heading = "heading",
  image = "image",
  youtube = "youtube",
  notation = "notation",
  drawing = "drawing",
  divider = "divider",
  instructorNote = "instructorNote",
  custom = "custom",
}

export enum ResourceType {
  url = "url",
  documentStorage = "documentStorage",
}

export interface FileResource {
  base: ResourceType;
  urlParams?: {
    url: string;
  };
  documentStorageParams?: {
    path: string;
  };
}

export interface ListItem {
  title: string;
  doable: boolean;
  done?: boolean;
}

export enum ListType {
  bullets = "bullets",
  numbers = "numbers",
  checkboxes = "checkboxes",
}

export interface LessonBlock {
  id: string;
  resource?: FileResource;
  textContent: string;
  headline?: string;
  link?: string;
  list?: LessonBlockList;
  listType?: ListType; //deprecated 1/2021
  listItems?: ListItem[]; //deprecated 1/2021
  type: BlockType;
  dueDate?: number;
  modifiedDate: number;
  deleted?: boolean;
  customData?: CustomBlockData;
}

export interface LessonBlockList {
  type: ListType;
  children: LessonBlockListItem[];
}

export interface LessonBlockListItem {
  id: string;
  title: string;
  list?: LessonBlockList;
  meta?: LessonBlockListItemMeta;
}

export interface LessonBlockListItemMeta {
  done?: boolean;
}

export interface CustomBlockData {
  type: string;
  data: string;
}

export interface Student {
  id: string;
  firstName: string;
  lastName: string;
  school: string;

  lessonAmount?: number;
  metaInfo: string;

  picture?: FileResource;

  defaultLessonType: LessonType;

  contactInfo: {
    phone: string;
    email: string;
  };

  modifiedDate: number;
  deleted?: boolean;
}

export interface Instructor {
  id: string;
  firstName: string;
  lastName: string;
  contactInfo: {
    phone: string;
    email: string;
  };
  picture?: FileResource;
  emailMeta?: string;
  invoiceMeta?: string;
  studentIDs: string[];
  modifiedDate: number;
}

export interface LessonBlockResponse {
  id: string;
  blockID: string;
  creatorID: string;
  fromInstructor?: boolean;
  textContent: string;
  fileResource?: string;
  fileResourceSize?: number;
  modifiedDate: Date;
}

export type AccessToken = {
  id: string;
  groupID: string;
  redeemed?: boolean;
  redeemedDate?: firebase.firestore.Timestamp; //might need to change the type if I get it from the server, generate it, etc
  associatedUserID?: string;
  userRole: string;
  createdDate: firebase.firestore.Timestamp;
};
