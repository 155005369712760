import Constants from 'expo-constants';

const firebaseConfig = {
  apiKey: 'AIzaSyDb1q2316ufXasYKJ5EWzcC1gdORnvqRnU',
  authDomain: 'lessonmanager-d0134.firebaseapp.com',
  databaseURL: 'https://lessonmanager-d0134.firebaseio.com',
  projectId: 'lessonmanager-d0134',
  storageBucket: 'lessonmanager-d0134.appspot.com',
  messagingSenderId: '208830060812',
  appId: '1:208830060812:web:8da0dda1b9c8ac01101129',
};

const firebaseBetaConfig = {
  apiKey: 'AIzaSyCx3rs9Jk3zPjzfBMZVxaBAvddo2awA5U0',
  authDomain: 'lessonkeeperbeta.firebaseapp.com',
  databaseURL: 'https://lessonkeeperbeta.firebaseio.com',
  projectId: 'lessonkeeperbeta',
  storageBucket: 'lessonkeeperbeta.appspot.com',
  messagingSenderId: '993279605314',
  appId: '1:993279605314:web:3294f117c94c788fb7cbdb',
  measurementId: 'G-JNLWE79HZV',
};

export enum EnvironmentNames {
  DEV = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  MGS = 'MGS',
  UNKNOWN = 'UNKNOWN',
}

export function getEnvironment(): {
  envName: EnvironmentNames;
  firebaseConfig: typeof firebaseConfig;
  defaultDisplayOptions: {
    allowResponses: boolean;
    allowLiveUpdates: boolean;
    showInstructorEmail: boolean;
  };
} {
  let releaseChannel: string = Constants.manifest.extra.deployTarget.toLowerCase();

  console.log('Release channel: ' + releaseChannel);

  if (releaseChannel === undefined || releaseChannel.indexOf('dev') !== -1) {
    // no releaseChannel (is undefined) in dev
    return {
      envName: EnvironmentNames.DEV,
      firebaseConfig: firebaseBetaConfig,
      defaultDisplayOptions: {
        allowResponses: true,
        allowLiveUpdates: true,
        showInstructorEmail: true,
      },
    }; // dev env settings
  }
  if (releaseChannel.indexOf('prod') !== -1) {
    // matches prod-v1, prod-v2, prod-v3
    return {
      envName: EnvironmentNames.PRODUCTION,
      firebaseConfig: firebaseConfig,
      defaultDisplayOptions: {
        allowResponses: true,
        allowLiveUpdates: true,
        showInstructorEmail: true,
      },
    }; // prod env settings
  }
  if (releaseChannel.indexOf('mgs') !== -1) {
    // matches staging-v1, staging-v2
    return {
      envName: EnvironmentNames.MGS,
      firebaseConfig: firebaseConfig,
      defaultDisplayOptions: {
        allowResponses: true,
        allowLiveUpdates: true,
        showInstructorEmail: false,
      },
    }; // stage env settings
  }

  // ******** THIS SHOULD NEVER GET HERE, SINCE THE FIRST UNDEFINED CASE SHOULD TAKE CARE OF IT *********** //
  return {
    envName: EnvironmentNames.UNKNOWN,
    firebaseConfig: firebaseConfig,
    defaultDisplayOptions: {
      allowResponses: true,
      allowLiveUpdates: true,
      showInstructorEmail: true,
    },
  };
}
