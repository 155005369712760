import * as chrono from 'chrono-node';
import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { LKButton } from './LKButton';
import { TextInput } from 'react-native';
import { View } from '../Themed';

export const TimePickerView: React.FunctionComponent<{
  inputTime: Date;
  callback: (date: Date) => void;
  showDate: boolean;
  showTime: boolean;
}> = (props) => {
  const [text, setText] = React.useState(generateTimeText(props.inputTime));

  function generateTimeText(input: Date) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
    };
    if (props.showDate && props.showTime) {
      return input.toLocaleString([], { ...dateOptions, ...timeOptions });
    }
    if (props.showDate) {
      return input.toLocaleDateString([], { ...dateOptions });
    }
    if (props.showTime) {
      return input.toLocaleTimeString([], { ...timeOptions });
    }
    return input.toLocaleString();
  }

  const parseAndFinish = () => {
    const newDate = chrono.parseDate(text);
    props.callback(newDate);
  };

  return (
    <View>
      <TextInput
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
        value={text}
        onChangeText={setText}
      />
      <LKButton onPress={parseAndFinish} title="Save" />
    </View>
  );
};
