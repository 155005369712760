import * as React from 'react';
import { ActivityIndicator, Button, TextInput } from 'react-native';
import { addGroupToUserAndRedeemCode } from '../../utility/firebaseUserFunctions';
import { AppStyles } from '../../style/Styles';
import { FirebaseContext } from '../../../AppState';
import { getQueryParameter } from '../../utility/general';
import { Text, View } from '../Themed';

export const UserGroupComponent: React.FunctionComponent<{}> = (props) => {
  const fbContext = React.useContext(FirebaseContext);

  const [groupID, setGroupID] = React.useState(
    getQueryParameter('groupID') ?? ''
  );
  const [accessCode, setAccessCode] = React.useState(
    getQueryParameter('accessCode') ?? ''
  );

  const [isWorking, setIsWorking] = React.useState(false);

  if (isWorking) {
    return <ActivityIndicator size="large" animating={true} />;
  }

  return (
    <View>
      <Text>Please enter a Group ID and access code to continue</Text>
      <View style={AppStyles.separator} />
      <Text style={AppStyles.defaultMarginBottom}>Group ID:</Text>
      <TextInput
        value={groupID}
        onChangeText={setGroupID}
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
      />
      <Text style={AppStyles.defaultMarginBottom}>Access code:</Text>
      <TextInput
        value={accessCode}
        onChangeText={setAccessCode}
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
      />
      <Button
        title="Submit"
        onPress={async () => {
          console.log('Submitting...');
          setIsWorking(true);
          const result = await addGroupToUserAndRedeemCode(
            groupID.trim(),
            accessCode.trim()
          );
          if (result.success) {
            alert(
              'Success! Your group and access code have been added to your account.'
            );
            setIsWorking(false);
            fbContext.actions.refreshUserGroup();
          } else {
            const error =
              result.systemError ?? result.userError ?? 'Unknown error';
            alert('There was an error: ' + error);
            setIsWorking(false);
          }
        }}
      />
    </View>
  );
};
