import * as React from "react";
import { AppStyles } from "../style/Styles";
import { arrayMove } from "../utility/general";
import { BlockType, LessonBlock, ListItem, ListType } from "../types/Types";
import { Button, TextInput } from "react-native";
import { OLD_EditableListItem } from "../components/blockEditors/ListBlockEditorView";
import { generateModifiedDate, uuidv4 } from "../utility/lessonKeeperFunctions";
import { OLD_ListItemView } from "../components/blocks/ListBlockView";
import { LKButton } from "../components/basicComponents/LKButton";
import { Text, View } from "../components/Themed";

export const MGSBlockType = "MGS";

export interface MGSBlockContent {
  recordingLink?: string;
  listItems?: ListItem[];
  listType?: ListType;
}

export function createEmptyMGSBlock() {
  const emptyContent: MGSBlockContent = {
    listItems: [
      { title: "", doable: true, done: false },
      { title: "", doable: true, done: false },
      { title: "", doable: true, done: false },
    ],
  };
  const primitiveBlock: LessonBlock = {
    id: uuidv4(),
    type: BlockType.custom,
    textContent: "",
    modifiedDate: generateModifiedDate(),
    customData: {
      type: MGSBlockType,
      data: JSON.stringify(emptyContent),
    },
  };
  return primitiveBlock;
}

export const MGSBlockView: React.FunctionComponent<{ block: LessonBlock }> = (
  props
) => {
  const { block } = props;
  if (!block.customData) {
    return null;
  }
  const mgsBlockContent = JSON.parse(block.customData.data) as MGSBlockContent;

  return (
    <View>
      {/* Display the lesson link */}

      {mgsBlockContent.recordingLink ? (
        <>
          <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
            Recording link:
          </Text>
          <a href={mgsBlockContent.recordingLink}>
            {mgsBlockContent.recordingLink}
          </a>
        </>
      ) : null}

      {/* Display the list */}

      <View style={AppStyles.separator} />

      <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
        Practice items:
      </Text>

      {mgsBlockContent.listItems &&
      mgsBlockContent.listItems.filter((i) => i.title.length).length > 0 ? (
        <>
          {mgsBlockContent.listItems
            .filter((i) => i.title.length)
            .map((item, index) => (
              <OLD_ListItemView
                key={index}
                item={item}
                itemIndex={index}
                listType={mgsBlockContent.listType ?? ListType.bullets}
              />
            ))}
        </>
      ) : null}
    </View>
  );
};

export const MGSBlockEditorView: React.FunctionComponent<{
  block: LessonBlock;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  //using the underscore here so I don't get confused and access the normal properties of the block
  const [_block, setBlock] = React.useState<LessonBlock>(props.block);

  const mgsData: MGSBlockContent | undefined = JSON.parse(
    _block.customData?.data ?? "{}"
  );

  const listItems = mgsData?.listItems ?? [];
  const listType = mgsData?.listType ?? ListType.checkboxes;

  const setBlockWithNewMGSData = (newMGSData: MGSBlockContent) => {
    const newBlock = {
      ..._block,
      customData: {
        type: MGSBlockType,
        data: JSON.stringify(newMGSData),
      },
    };
    setBlock(newBlock);
  };

  return (
    <View style={AppStyles.container}>
      <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
        MGS Template
      </Text>
      <View style={AppStyles.separator} />
      <Text style={AppStyles.defaultMarginBottom}>Lesson recording link:</Text>
      <TextInput
        style={[
          AppStyles.basicTextInput,
          { flex: 0 },
          AppStyles.defaultMarginBottom,
        ]}
        value={mgsData?.recordingLink ?? ""}
        onChangeText={(newText) => {
          const newMGSData = {
            ...mgsData,
            recordingLink: newText,
          };

          setBlockWithNewMGSData(newMGSData);
        }}
      />
      <View style={AppStyles.separator} />

      {/* Do the list stuff here */}

      <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
        Practice items:
      </Text>

      {listItems.map((listItem, index) => {
        return (
          <OLD_EditableListItem
            key={`${listItem.title}${index}`}
            listItem={listItem}
            index={index}
            isLast={index === (listItems ?? []).length - 1}
            listStyle={listType ?? ListType.bullets}
            editListItem={(newItem) => {
              //var newBlock = { ...block };
              listItems[index] = newItem;
              setBlockWithNewMGSData({ ...mgsData, listItems });
            }}
            deleteListItem={() => {
              setBlockWithNewMGSData({
                ...mgsData,
                listItems: listItems?.filter((i) => i !== listItem),
              });
            }}
            moveListItem={(shift) => {
              let updatedData: MGSBlockContent = { ...mgsData };
              updatedData.listItems = arrayMove(
                updatedData.listItems ?? [],
                index,
                index + shift
              );
              setBlockWithNewMGSData(updatedData);
            }}
          />
        );
      })}

      <LKButton
        title="Add item"
        onPress={() => {
          let newListItems = [
            ...listItems,
            {
              title: "",
              doable: listType == ListType.checkboxes,
              done: false,
            },
          ];

          setBlockWithNewMGSData({ ...mgsData, listItems: newListItems });
        }}
      />

      <View style={AppStyles.separator} />
      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(_block);
          props.finish();
        }}
      />
    </View>
  );
};
