import * as Linking from 'expo-linking';
import { LinkingOptions } from '@react-navigation/native';

export const LinkingConfiguration: LinkingOptions = {
  enabled: true,
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      MainScreen: {
        path: 'main',
        initialRouteName: 'MainScreen',
        screens: {
          LessonScreen: {},
          StudentEditorScreen: {},
          InstructorEditorScreen: {},
          AdminScreen: {},
        },
      },
      LessonScreen: {
        path: 'lesson',
        screens: { BlockEditorScreen: {} }, //this appears to do nothing
      },
      StudentEditorScreen: {
        path: 'student',
      },
      InstructorEditorScreen: {
        path: 'instructor',
      },
      BlockEditorScreen: {
        path: 'blockEditor',
      },
      AdminScreen: {
        path: 'admin',
      },
      // Root: {
      //   path: 'root',
      //   initialRouteName: 'TabOne',
      //   screens: {
      //     TabOne: {
      //       screens: {
      //         TabOneScreen: 'one',
      //         DetailsScreen: 'details',
      //       },
      //     },
      //     TabTwo: {
      //       screens: {
      //         TabTwoScreen: 'two',
      //       },
      //     },
      //   },
      // },
      NotFound: '*',
    },
  },
};
