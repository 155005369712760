import * as React from 'react';
import { getYouTubeID } from '../../utility/lessonKeeperFunctions';

export const YouTubeVideoView: React.FunctionComponent<{
  caption: string;
  link: string;
}> = (props) => (
  <>
    <div className="video-responsive">
      <iframe
        width="560"
        height="315"
        style={{border: "none"}}
        src={'https://www.youtube.com/embed/' + getYouTubeID(props.link) ?? ''}
      ></iframe>
    </div>
    {props.caption.length > 0 ? (
      <span className="caption">{props.caption}</span>
    ) : null}
  </>
);
