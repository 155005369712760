import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Button, TextInput } from 'react-native';
import { LessonBlock } from '../../types/Types';
import { Text, View } from 'react-native';
import { YouTubeVideoView } from '../mediaComponents/YouTubeVideoView';

export const YouTubeBlockEditorView: React.FunctionComponent<{
  block: LessonBlock;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const [block, setBlock] = React.useState<LessonBlock>(props.block);

  return (
    <View>
      <Text style={AppStyles.defaultMarginBottom}>YouTube link:</Text>
      <TextInput
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
        value={block.link ?? ''}
        onChangeText={(newText) => {
          const newBlock = { ...block, link: newText };
          setBlock(newBlock);
        }}
      />
      <View style={{ alignItems: 'center' }}>
        {block.link ? (
          <YouTubeVideoView link={block.link} caption={''} />
        ) : null}
      </View>
      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(block);
          props.finish();
        }}
      />
    </View>
  );
};
