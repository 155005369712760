import * as React from 'react';
import {
  FirebaseState,
  FirebaseSyncState,
  Instructor,
  LessonBlock,
  LessonModel,
  Student
} from './src/types/Types';

export const blankFirebaseSyncState: FirebaseSyncState = {
  lessons: [],
  students: [],
  instructors: [],
  sync: {
    hasSyncedInstructors: false,
    hasSyncedLessons: false,
    hasSyncedStudents: false,
  },
};

export const blankFirebaseState: FirebaseState = {
  login: {
    uid: undefined,
    selectedUserID: undefined,
    userGroup: {
      synced: false,
    },
  },
  syncState: blankFirebaseSyncState,
  actions: {
    refreshUserGroup: () => { },
    selectUserID: (uid: string) => { },
  },
};

export const FirebaseContext = React.createContext<FirebaseState>(
  blankFirebaseState
);

type LessonKeeperPasteboard = {
  copiedBlocks: LessonBlock[];
  fromLessonID?: string;
  fromInstructorID?: string;
};

export type PasteboardAction =
  | { type: 'addCopiedBlock'; block: LessonBlock; fromLessonID: string; fromInstructorID: string }
  | { type: 'setCopiedBlocks'; blocks: LessonBlock[]; fromLessonID: string; fromInstructorID: string };

export const PasteboardContext = React.createContext<{
  pasteboard: LessonKeeperPasteboard;
  dispatch: React.Dispatch<PasteboardAction>;
}>({
  pasteboard: { copiedBlocks: [] },
  dispatch: () => { },
});

export function pasteboardReducer(
  state: LessonKeeperPasteboard,
  action: PasteboardAction
) {
  console.log('Pasteboard action: ');
  console.log(action);
  switch (action.type) {
    case 'addCopiedBlock':
      return {
        ...state,
        copiedBlocks: [...state.copiedBlocks, action.block],
        fromLessonID: action.fromLessonID,
        fromInstructorID: action.fromInstructorID,
      };
    case 'setCopiedBlocks':
      return {
        ...state,
        copiedBlocks: action.blocks,
        fromLessonID: action.fromLessonID,
        fromInstructorID: action.fromInstructorID,
      };
    default:
      return { ...state };
  }
}

export function syncStateReducer(
  state: FirebaseSyncState,
  action: SyncStateAction
) {
  switch (action.type) {
    case 'setLessons':
      return {
        ...state,
        lessons: action.lessons,
        sync: { ...state.sync, hasSyncedLessons: true },
      };
    case 'setStudents':
      return {
        ...state,
        students: action.students,
        sync: { ...state.sync, hasSyncedStudents: true },
      };
    case 'setInstructors':
      return {
        ...state,
        instructors: action.instructors,
        sync: { ...state.sync, hasSyncedInstructors: true },
      };
    default:
      return { ...state };
  }
}

type SyncStateAction =
  | { type: 'setStudents'; students: Student[] }
  | { type: 'setLessons'; lessons: LessonModel[] }
  | { type: 'setInstructors'; instructors: Instructor[] };
