import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Button, TextInput } from 'react-native';
import { LessonBlock } from '../../types/Types';
import { Text, View } from 'react-native';

export const TextBlockEditorView: React.FunctionComponent<{
  block: LessonBlock;
  headlineOnly: boolean;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const [block, setBlock] = React.useState<LessonBlock>(props.block);

  return (
    <View style={[AppStyles.container, { backgroundColor: 'clear' }]}>
      {/* <Text>Text Editor View: {props.block.textContent}</Text> */}
      <Text style={AppStyles.defaultMarginBottom}>Headline:</Text>
      <TextInput
        style={[
          AppStyles.basicTextInput,
          { flex: 0 },
          AppStyles.defaultMarginBottom,
        ]}
        value={block?.headline ?? ''}
        onChangeText={(newText) => {
          const newBlock = { ...block, headline: newText };
          setBlock(newBlock);
        }}
      />
      {!props.headlineOnly && (
        <>
          <Text>Content:</Text>
          <TextInput
            style={[AppStyles.basicTextInput, AppStyles.multiLineTextInput]}
            multiline={true}
            value={block?.textContent ?? ''}
            onChangeText={(newText) => {
              const newBlock = { ...block, textContent: newText };
              setBlock(newBlock);
            }}
          />
        </>
      )}

      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(block);
          props.finish();
        }}
      />
    </View>
  );
};
