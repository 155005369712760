import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Button, TextInput } from 'react-native';
import { LessonBlock } from '../../types/Types';
import { Text, View } from 'react-native';

export const InstructorNoteBlockEditor: React.FunctionComponent<{
  block: LessonBlock;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const [block, setBlock] = React.useState<LessonBlock>(props.block);

  return (
    <View style={AppStyles.container}>
      <Text>Instructor note:</Text>
      <TextInput
        style={[AppStyles.basicTextInput]}
        multiline={true}
        value={block?.textContent ?? ''}
        onChangeText={(newText) => {
          const newBlock = { ...block, textContent: newText };
          setBlock(newBlock);
        }}
      />

      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(block);
          props.finish();
        }}
      />
    </View>
  );
};
