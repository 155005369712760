export function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
  let toRet = [...arr];
  const element = arr[fromIndex];
  toRet.splice(fromIndex, 1);
  toRet.splice(toIndex, 0, element);
  return toRet;
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function getQueryParameter(param: string) {
  const url = new URL(window.location.href);
  return url.searchParams.get(param);
}
