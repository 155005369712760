import { Ionicons } from "@expo/vector-icons";
import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import * as React from "react";
import {
  ActivityIndicator,
  Button,
  ScrollView,
  Switch,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { FirebaseContext } from "../../AppState";
import { UserGroupComponent } from "../components/login/UserGroupComponent";
import { StudentView } from "../components/StudentView";
import { Text, View } from "../components/Themed";
import { RootStackParamList } from "../navigation";
import { AppStyles } from "../style/Styles";
import { Instructor, LessonModel, Student } from "../types/Types";
import {
  firebaseCurrentUserObject,
  hasSynced,
  uploadInstructorToSync,
  uploadLessonToSync,
  uploadStudentToSync,
} from "../utility/firebaseFunctions";
import {
  generateModifiedDate,
  getCurrentInstructor,
  uuidv4,
} from "../utility/lessonKeeperFunctions";
import { getEnvironment } from "../utility/releaseChannels";

export type MainScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "MainScreen"
>;

type MainScreenRouteProp = RouteProp<RootStackParamList, "MainScreen">;

type MainScreenProps = {
  navigation: MainScreenNavigationProp;
  route: MainScreenRouteProp;
};

export default function MainScreen(props: MainScreenProps) {
  const { navigation } = props;
  const fbContext = React.useContext(FirebaseContext);

  const [showDeleted, setShowDeleted] = React.useState(false);

  const [instructor, setInstructor] = React.useState<Instructor>();

  const [searchTerm, setSearchTerm] = React.useState("");

  const [displayedStudents, setDisplayedStudents] = React.useState<
    Array<Student>
  >([]);

  React.useEffect(() => {
    const filtered = fbContext.syncState.students.filter((i) => {
      if (!searchTerm.length) {
        return true;
      }
      const normalizedSearch = searchTerm.toLowerCase().trim();
      return (
        (i.firstName ?? "").toLowerCase().indexOf(normalizedSearch) !== -1 ||
        (i.lastName ?? "").toLowerCase().indexOf(normalizedSearch) !== -1 ||
        (i.firstName + " " + i.lastName ?? "")
          .toLowerCase()
          .indexOf(normalizedSearch) !== -1
      );
    });

    if (!compare(filtered, displayedStudents)) {
      setDisplayedStudents(filtered);
    }
  }, [fbContext.syncState.students, searchTerm]);

  React.useEffect(() => {
    setInstructor(getCurrentInstructor(fbContext.syncState.instructors));
  }, [fbContext.syncState.instructors]);

  // React.useEffect(() => {
  //   console.log('Search: ', searchTerm, displayedStudents.length);
  // }, [searchTerm]);

  const { selectedUserID } = fbContext.login;

  const lessonsByStudent: Array<{
    student: Student;
    lessons: LessonModel[];
  }> = React.useMemo(
    () =>
      displayedStudents
        .filter((i) => {
          if (showDeleted) {
            return true;
          }
          return !i.deleted;
        })
        .sort((a, b) => {
          const aName = `${a.firstName} ${a.lastName}`;
          const bName = `${b.firstName} ${b.lastName}`;
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        })
        .map((student) => {
          return {
            student,
            lessons: fbContext.syncState.lessons
              .filter((i) => {
                if (showDeleted) {
                  return true;
                }
                return !i.deleted;
              })
              .filter((i) => i.lessonMeta.studentIDs.indexOf(student.id) !== -1)
              .sort((a, b) => b.lessonMeta.lessonDate - a.lessonMeta.lessonDate)
              .reverse(),
          };
        }),
    [displayedStudents, fbContext.syncState.lessons, showDeleted]
  );

  const addLesson = async (forStudent: Student) => {
    if (!selectedUserID) {
      return;
    }
    const newLesson: LessonModel = {
      id: uuidv4(),
      type: { base: "private" },
      lessonMeta: {
        studentIDs: [forStudent.id],
        instructorID: getCurrentInstructor(fbContext.syncState.instructors).id,
        lessonDate: generateModifiedDate(),
        groupID: fbContext.login.userGroup?.group,

        displayOptions: getEnvironment().defaultDisplayOptions,
      },
      payInfo: {
        amount: 0,
        method: {
          base: "unpaid",
        },
      },
      blocks: [],
      modifiedDate: generateModifiedDate(),
    };
    await uploadLessonToSync(selectedUserID, newLesson);
    fbContext.syncState.lessons.push(newLesson);
    navigation.push("LessonScreen", {
      lessonID: newLesson.id,
      selectedUserID: fbContext.login.selectedUserID,
    });
  };

  const _addLesson = React.useCallback<{
    (forStudent: Student): Promise<void>;
  }>(
    async (forStudent) => {
      console.log("Called...");
      await addLesson(forStudent);
    },
    [fbContext.syncState.instructors, fbContext.login]
  );

  if (!selectedUserID) {
    return null;
  }

  const addStudent = async () => {
    const newStudent: Student = {
      id: uuidv4(),
      modifiedDate: generateModifiedDate(),
      firstName: "",
      lastName: "",
      contactInfo: {
        phone: "",
        email: "",
      },
      defaultLessonType: { base: "private" },
      school: "",
      metaInfo: "",
    };
    await uploadStudentToSync(selectedUserID, newStudent);
    navigation.push("StudentEditorScreen", {
      studentID: newStudent.id,
      selectedUserID: fbContext.login.selectedUserID,
    });
  };

  const createInstructor = async () => {
    const user = firebaseCurrentUserObject();
    const userNames = user?.displayName?.split(" ");
    const newInstructor: Instructor = {
      id: uuidv4(),
      firstName: (userNames && userNames.length && userNames[0]) || "",
      lastName: (userNames && userNames.length > 1 && userNames[1]) || "",
      contactInfo: {
        email: user?.email ?? "",
        phone: "",
      },
      studentIDs: [],
      modifiedDate: generateModifiedDate(),
    };
    await uploadInstructorToSync(selectedUserID, newInstructor);
    navigation.push("InstructorEditorScreen", {
      instructorID: newInstructor.id,
      selectedUserID: fbContext.login.selectedUserID,
    });
  };

  if (!fbContext.login.uid) {
    return null;
  }

  if (!hasSynced(fbContext)) {
    console.warn("Not synced...");
    return (
      <View
        style={[
          AppStyles.container,
          { alignItems: "center", justifyContent: "center" },
        ]}
      >
        <ActivityIndicator size='large' />
      </View>
    );
  }

  //transitionUserClaims(); //Use this?

  if (fbContext.login.userGroup.group === undefined) {
    console.warn("No user group -- displaying UserGroupComponent");
    return (
      <View style={[AppStyles.container, { justifyContent: "center" }]}>
        <View style={AppStyles.mainScreenRoadblock}>
          <UserGroupComponent />
        </View>
      </View>
    );
  } else {
    console.log("User group:");
    console.log(fbContext.login.userGroup.group);
  }

  if (fbContext.syncState.instructors.length < 1) {
    return (
      <View style={AppStyles.container}>
        <View style={{ marginTop: 40, width: 300, alignSelf: "center" }}>
          <Text style={AppStyles.defaultMarginBottom}>
            Before you can continue using LessonKeeper, you have to set up an
            instructor profile.
          </Text>
          <Button title='Create new instructor' onPress={createInstructor} />
        </View>
      </View>
    );
  }

  return (
    <View style={AppStyles.container}>
      <ScrollView
        contentContainerStyle={[
          AppStyles.screenScroller,
          AppStyles.mainPageScroller,
        ]}
      >
        <View style={AppStyles.mainPageStudentHeader}>
          <Text
            style={[AppStyles.h1, { alignSelf: "center", marginBottom: 10 }]}
          >
            Students
          </Text>
          <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
            <Text style={AppStyles.defaultMarginRight}>Search:</Text>
            <TextInput
              value={searchTerm}
              //defaultValue={'Student name'}
              onChangeText={setSearchTerm}
              style={[{ flex: 1 }, AppStyles.basicTextInput]}
            />
          </View>
          <TouchableOpacity
            onPress={addStudent}
            style={[AppStyles.row, { alignSelf: "center" }]}
          >
            <Ionicons
              name='ios-add-circle'
              size={24}
              style={AppStyles.defaultMarginRight}
            />
            <Text>Add student</Text>
          </TouchableOpacity>
        </View>
        {lessonsByStudent.map((student) => (
          <StudentView
            student={student.student}
            lessons={student.lessons}
            addLesson={_addLesson}
            key={student.student.id}
          />
        ))}
        {instructor && (
          <>
            <View
              style={[AppStyles.separator, AppStyles.defaultMarginBottom]}
            />
            <View
              style={{
                alignSelf: "center",
                alignItems: "center",
                backgroundColor: "clear",
              }}
            >
              <Text style={AppStyles.h2}>Instructor</Text>
              <TouchableOpacity
                onPress={() =>
                  navigation.push("InstructorEditorScreen", {
                    instructorID: instructor.id,
                    selectedUserID: fbContext.login.selectedUserID,
                  })
                }
                style={AppStyles.centerContent}
              >
                <Ionicons
                  name='ios-person'
                  size={32}
                  style={AppStyles.defaultMarginBottom}
                />
                <Text>
                  {instructor.firstName} {instructor.lastName}
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
        <View style={[AppStyles.separator, AppStyles.defaultMarginBottom]} />
        <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
          <Text>Show deleted items?</Text>
          <Switch
            value={showDeleted}
            onValueChange={setShowDeleted}
            style={{ marginLeft: 10 }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

function compare<T>(array1: Array<T>, array2: Array<T>) {
  // if the other array is a falsy value, return
  if (!array2) return false;

  // compare lengths - can save a lot of time
  if (array1.length != array2.length) return false;

  for (var i = 0, l = array1.length; i < l; i++) {
    // Check if we have nested arrays
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      // recurse into the nested arrays
      if (array1[i] !== array2[i]) return false;
    } else if (array1[i] != array2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
}
