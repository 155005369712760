import * as React from 'react';
import BottomTabNavigator from './BottomTabNavigator';
import MainScreen, { MainScreenNavigationProp } from '../screens/MainScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import { AdminScreen } from '../screens/AdminScreen';
import { AppStyles } from '../style/Styles';
import { BlockEditorScreen } from '../screens/BlockEditorScreen';
import { Button, ColorSchemeName, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { FirebaseContext } from '../../AppState';
import { FirebaseState, LessonBlock, LessonModel } from '../types/Types';
import { InstructorEditorScreen } from '../screens/InstructorEditorScreen';
import { LessonScreen } from '../screens/LessonScreen';
import { LinkingConfiguration } from './LinkingConfiguration';
import { LKButton } from '../components/basicComponents/LKButton';
import { LoginLogoutView } from '../components/login/LoginComponent';
import { StudentEditorScreen } from '../screens/StudentEditorScreen';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  useNavigation,
} from '@react-navigation/native';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started

type NavigationProps = {
  colorScheme: ColorSchemeName;
};

export default function Navigation(props: NavigationProps) {
  const fbContext = React.useContext(FirebaseContext);

  return (
    <>
      {/* <Text>Context uid: {fbContext.login.uid}</Text> */}
      <NavigationContainer
        linking={LinkingConfiguration}
        theme={props.colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      >
        <RootNavigator />
      </NavigationContainer>
    </>
  );
}

export type RootStackParamList = {
  MainScreen: { selectedUserID?: string };
  NotFound: undefined;
  LessonScreen: { lessonID: string; selectedUserID?: string };
  StudentEditorScreen: { studentID: string; selectedUserID?: string };
  InstructorEditorScreen: { instructorID: string; selectedUserID?: string };
  BlockEditorScreen: {
    block: LessonBlock;
    lessonID: string;
    commitBlockFunction: (block: LessonBlock) => void;
    selectedUserID?: string;
  };
  AdminScreen: { selectedUserID?: string };
};

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

type RootNavigatorProps = {};

function RootNavigator(props: RootNavigatorProps) {
  const fbContext = React.useContext(FirebaseContext);

  return (
    <Stack.Navigator
      screenOptions={(optionsProps) => ({
        headerShown: true,
        headerRight: (props) => {
          return (
            <View style={[AppStyles.defaultMarginRight, AppStyles.row]}>
              {fbContext.login.userGroup.isAdmin ? (
                <LKButton
                  onPress={() => {
                    console.log('Users:');
                    console.log(fbContext.login.userGroup.usersToAdministrate);
                    optionsProps.navigation.navigate('AdminScreen');
                  }}
                  title="Admin"
                  style={[AppStyles.defaultMarginRight]}
                />
              ) : null}

              <LoginLogoutView style={[AppStyles.defaultMarginRight]} />
            </View>
          );
        },
      })}
    >
      <Stack.Screen
        name="MainScreen"
        component={MainScreen}
        options={{ title: 'LessonKeeper - BETA' }}
      />
      <Stack.Screen name="LessonScreen" component={LessonScreen} />
      <Stack.Screen
        name="StudentEditorScreen"
        component={StudentEditorScreen}
      />
      <Stack.Screen
        name="InstructorEditorScreen"
        component={InstructorEditorScreen}
      />
      <Stack.Screen
        name="AdminScreen"
        component={AdminScreen}
        options={{
          title: 'Admin',
        }}
      />
      <Stack.Screen
        name="BlockEditorScreen"
        component={BlockEditorScreen}
        options={{ title: 'Edit' }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
    </Stack.Navigator>
  );
}
