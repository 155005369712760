import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { LessonBlock } from '../../types/Types';
import { Text, View } from '../Themed';

type Props = {
  block: LessonBlock;
};

export const TextBlockView = (props: Props) => {
  return (
    <View>
      {props.block.headline && (
        <Text style={[AppStyles.defaultMarginBottom, AppStyles.h2]}>
          {props.block.headline}
        </Text>
      )}
      <Text>{props.block.textContent}</Text>
    </View>
  );
};

export const HeadingBlockView = (props: Props) => {
  return (
    <View>
      <Text style={AppStyles.h2}>
        {props.block.headline ?? props.block.textContent}
      </Text>
    </View>
  );
};
