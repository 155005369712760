import { AccessToken, Instructor, Student } from "../types/Types";
import {
  deleteLessonFromShare,
  deleteLessonFromSync,
  deleteServerFile,
  deleteStudentFromSync,
  firebaseObject,
  getFirebaseUploadRef,
  getResponseFirebaseUploadRef,
  instructorSyncCollection,
  retrieveInstructors_Promise,
  retrieveLessons_Promise,
  retrieveResponses_Promise,
  retrieveStudents_Promise,
  uploadLessonToShare,
  uploadLessonToSync,
  uploadResponses,
  uploadStudentToSync,
} from "./firebaseFunctions";
import { notEmpty } from "./general";

const firebase = firebaseObject();

type GetGroupUsersResponse = {
  systemError?: string;
  userError?: string;
  success: boolean;
  users?: string[];
};

type GetGroupUsersParams = {
  groupID: string;
};

export async function transitionUserClaims() {
  //return;

  const result = await firebaseObject()
    .functions()
    .httpsCallable("addUserRolesClaims")({ groupID: "MontavillaGuitarStudio" }); //beta site uses montavillaGroup
  console.log("Result!");
  console.log(result);
}

export async function retrieveGroupInstructors(groupID: string) {
  const userDocs = await firebaseObject()
    .firestore()
    .collection("groups")
    .doc(groupID)
    .collection("users")
    .get();
  return userDocs.docs.map((i) => ({ id: i.id, role: i.data().role }));
}

export async function getUsersNameBasedOnInstructor(userID: string) {
  try {
    console.log("Getting user name based on instructor for: ", userID);
    const instructorDocCollection = await instructorSyncCollection(userID)
      .orderBy("modifiedDate", "desc")
      .limit(1)
      .get();
    if (!instructorDocCollection.docs.length) {
      return undefined;
    }
    const instructorDoc = instructorDocCollection.docs[0].data() as Instructor;
    return `${instructorDoc.firstName} ${instructorDoc.lastName}`;
  } catch (error) {
    console.error(`Error getting user name for ${userID}:`, error);
    return undefined;
  }
}

type AddAccessTokenResponse = {
  success: boolean;
  systemError?: string;
  userError?: string;
  accessToken?: {
    groupID: string;
    tokenID: string;
  };
};

type AddAccessTokenParams = {
  groupID: string;
  newUserRole: string;
};

export async function generateAccessCode(groupID: string, newUserRole: string) {
  const addAccessToken = firebaseObject()
    .functions()
    .httpsCallable("addAccessToken");
  const params: AddAccessTokenParams = { groupID, newUserRole };

  try {
    const resultData = (await addAccessToken(params))
      .data as AddAccessTokenResponse;
    console.log("Got result:");
    console.log(resultData);
  } catch (error) {
    console.error("Error");
    console.error(error);
  }
}

export async function deleteAccessCode(groupID: string, tokenID: string) {
  const deleteAccessToken = firebaseObject()
    .functions()
    .httpsCallable("deleteAccessToken");
  const params = { groupID, tokenID };
  try {
    const resultData = (await deleteAccessToken(params))
      .data as AddAccessTokenResponse;
    console.log("Got results: ");
    console.log(resultData);
  } catch (error) {
    console.error("Error");
    console.error(error);
  }
}

export async function revokeAccessCode(
  groupID: string,
  tokenID: string,
  targetUserID: string
) {
  const changeUserGroupRole = firebaseObject()
    .functions()
    .httpsCallable("changeUserGroupRole");

  const params = { groupID, role: "DELETE", targetUserID };

  try {
    const resultData = (await changeUserGroupRole(params)).data as {
      success: boolean;
      systemError?: string;
      userError?: string;
    };
    console.log("Got results from changeUserGroupRole: ");
    console.log(resultData);

    if (!resultData.success) {
      return resultData;
    }
  } catch (error) {
    console.error("Error");
    console.error(error);
  }

  //delete the token
  return await deleteAccessCode(groupID, tokenID);
}

type GetGroupAccessTokensResponse = {
  success: boolean;
  systemError?: string;
  userError?: string;
  accessTokens?: AccessToken[];
};

type GetGroupAccessTokensParams = {
  groupID: string;
};

export async function getGroupAccessTokens(groupID: string) {
  const tokenDocs = await firebaseObject()
    .firestore()
    .collection("groups")
    .doc(groupID)
    .collection("accessCodes")
    .get();
  const tokens: AccessToken[] = tokenDocs.docs.map((i) => {
    const data = i.data();
    return {
      ...data,
      id: i.id,
      groupID,
    } as unknown as AccessToken;
  });

  return tokens;
}

//unused
async function FUNCTION__getGroupAccessTokens(groupID: string) {
  const func = firebaseObject()
    .functions()
    .httpsCallable("getGroupAccessTokens");
  const params: GetGroupAccessTokensParams = { groupID };

  try {
    const resultData = (await func(params))
      .data as GetGroupAccessTokensResponse;
    console.log("Got result:");
    console.log(resultData);
    return resultData.accessTokens ?? [];
  } catch (error) {
    console.error("Error");
    console.error(error);
  }

  return [];
}

async function getStudentForTransfer(
  originalUserID: string,
  originalStudentID: string,
  newUserID: string,
  mergeToStudentID?: string
): Promise<Student | undefined> {
  const students = await retrieveStudents_Promise(
    mergeToStudentID ? newUserID : originalUserID
  );
  return students.find(
    (i) => i.id == (mergeToStudentID ? mergeToStudentID : originalStudentID)
  );
}

export async function transferStudent(
  originalUserID: string,
  originalStudentID: string,
  newUserID: string,
  mergeToStudentID: string | undefined,
  deleteRecords: boolean
) {
  const student = await getStudentForTransfer(
    originalUserID,
    originalStudentID,
    newUserID,
    mergeToStudentID
  );

  if (!student) {
    console.log("No student found");
    return;
  }

  const instructors = await retrieveInstructors_Promise(newUserID);
  let newInstructor: Instructor | undefined = instructors.length
    ? instructors[0]
    : undefined;

  const lessonsToTransfer = (await retrieveLessons_Promise(originalUserID))
    .filter((i) => i.lessonMeta.studentIDs.includes(originalStudentID))
    .map((i) => {
      return {
        ...i,
        lessonMeta: {
          ...i.lessonMeta,
          instructorID: newInstructor
            ? newInstructor.id
            : i.lessonMeta.instructorID,
          studentIDs: mergeToStudentID
            ? [mergeToStudentID]
            : i.lessonMeta.studentIDs,
        },
      };
    });
  console.log(
    "Going to transfer",
    lessonsToTransfer.map((i) => i.id)
  );

  //upload to the new instructor's sync
  //  uploadStudentToSync
  //  uploadLessonToSync
  if (!mergeToStudentID) {
    await uploadStudentToSync(newUserID, student);
    console.log("Uploaded student");
  }

  await Promise.all(
    lessonsToTransfer.map((lesson) => {
      return uploadLessonToSync(newUserID, lesson);
    })
  );
  console.log("Uploaded lessons");
  /* ************************************ */

  //upload to the new instructor's share
  //  uploadLessonToShare()
  await Promise.all(
    lessonsToTransfer.map((lesson) => {
      return uploadLessonToShare(newUserID, lesson, [student], newInstructor);
    })
  );
  /* ************************************ */

  //transfer files
  //  normally use uploadFileToLesson
  //  get the files, use a storage move function instead
  const filesToMove = lessonsToTransfer.flatMap((lesson) => {
    return lesson.blocks
      .filter((i) => i.deleted !== true)
      .map((i) => {
        if (i.resource?.documentStorageParams?.path) {
          return {
            filepath: i.resource.documentStorageParams.path,
            lessonID: lesson.id,
          };
        }
      })
      .filter(notEmpty);
  });
  console.log("Going to transfer files:", filesToMove);
  const fileMoves = filesToMove.map(async (file) => {
    const uploadRef = getFirebaseUploadRef(
      originalUserID,
      file.lessonID,
      file.filepath
    );
    console.log("Transfer:", uploadRef);
    const storage = firebase.storage().ref().child(uploadRef);
    const url = await storage.getDownloadURL();
    try {
      const fileData = await fetch(url);
      const blob = await fileData.blob();
      return await firebase
        .storage()
        .ref()
        .child(getFirebaseUploadRef(newUserID, file.lessonID, file.filepath))
        .put(blob);
    } catch {
      return undefined;
    }
  });
  (await Promise.all(fileMoves)).filter(notEmpty).map((i) => {
    console.log("Uploaded file", i.ref);
  });
  /* ************************************ */

  //Move the responses
  console.log("Going to get responses...");
  const responsesToTransfer = await Promise.all(
    lessonsToTransfer.map(async (lesson) => {
      const lessonResponses = await retrieveResponses_Promise(
        originalUserID,
        lesson.id
      );
      return { lessonID: lesson.id, responses: lessonResponses };
    })
  );

  console.log("Transfering responses:", responsesToTransfer);
  await Promise.all(
    responsesToTransfer.map(async (response) => {
      return await uploadResponses(
        response.responses,
        newUserID,
        response.lessonID
      );
    })
  );

  //see if any responses have files
  const responseFilesToTransfer = responsesToTransfer.map((response) => {
    return {
      lessonID: response.lessonID,
      files: response.responses.map((i) => i.fileResource).filter(notEmpty),
    };
  });
  const responseFileMoves = responseFilesToTransfer.flatMap(
    async (response) => {
      let moves = await Promise.all(
        response.files
          .map(async (file) => {
            const uploadRef = getResponseFirebaseUploadRef(
              originalUserID,
              response.lessonID,
              file
            );
            console.log("Transfer:", uploadRef);
            const storage = firebase.storage().ref().child(uploadRef);
            const url = await storage.getDownloadURL();
            try {
              const fileData = await fetch(url);
              const blob = await fileData.blob();
              return await firebase
                .storage()
                .ref()
                .child(
                  getResponseFirebaseUploadRef(
                    newUserID,
                    response.lessonID,
                    file
                  )
                )
                .put(blob);
            } catch {
              return undefined;
            }
          })
          .filter(notEmpty)
      );
      return moves.filter(notEmpty);
    }
  );
  await Promise.all(responseFileMoves);

  console.log("Transfer of responses complete");
  /* ************************************ */

  if (deleteRecords) {
    await deleteTransferRecords(
      originalUserID,
      originalStudentID,
      lessonsToTransfer.map((i) => i.id),
      filesToMove
    );
  }
}

async function deleteTransferRecords(
  originalUserID: string,
  originalStudentID: string,
  lessonIDs: Array<string>,
  files: Array<{ filepath: string; lessonID: string }>
): Promise<void> {
  //remove the old records in the sync +
  //remove old versions in share +
  //remove old files in storage +
  //remove old responses

  await Promise.all(
    lessonIDs.map((lessonID) => deleteLessonFromSync(originalUserID, lessonID))
  );
  await Promise.all(
    lessonIDs.map((lessonID) => deleteLessonFromShare(originalUserID, lessonID))
  );
  await Promise.all(
    files.map(async (file) => {
      return await deleteServerFile(
        getFirebaseUploadRef(originalUserID, file.lessonID, file.filepath)
      );
    })
  );
  await deleteStudentFromSync(originalUserID, originalStudentID);
}
