import * as React from 'react';
import { Text } from '../Themed';

export const PDFView: React.FunctionComponent<{
  caption: string;
  pdfURL: string;
}> = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {props.caption.length > 0 && <Text>{props.caption}</Text>}
    <iframe src={props.pdfURL} width="600px" height="500px"></iframe>
    <span className="caption">
      <a
        href={props.pdfURL}
        style={{ fontFamily: '-apple-system', color: '#34b575' }}
        download
      >
        PDF Document
      </a>
    </span>
  </div>
);
