import * as React from 'react';
import { AppStyles } from '../style/Styles';
import { editorForBlock } from '../components/BlockView';
import { RootStackParamList } from '../navigation';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Text, View } from '../components/Themed';

type ScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'BlockEditorScreen'
>;

type ScreenRouteProp = RouteProp<RootStackParamList, 'BlockEditorScreen'>;

type ScreenProps = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

export function BlockEditorScreen(props: ScreenProps) {
  return (
    <View style={[AppStyles.container, { padding: 4 }]}>
      {editorForBlock(
        props.route.params.block,
        props.route.params.lessonID,
        props.route.params.commitBlockFunction,
        () => {
          props.navigation.goBack();
        }
      )}
    </View>
  );
}
