import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import {
  ActivityIndicator,
  Picker,
  ScrollView,
  Switch,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import EnhancedModal from 'react-native-modal';
import { FirebaseContext } from '../../AppState';
import { LKButton } from '../components/basicComponents/LKButton';
import { Text, View } from '../components/Themed';
import { RootStackParamList } from '../navigation';
import { AppStyles } from '../style/Styles';
import { AccessToken, Student } from '../types/Types';
import {
  deleteAccessCode,
  generateAccessCode,
  getGroupAccessTokens,
  getUsersNameBasedOnInstructor,
  revokeAccessCode,
  transferStudent,
} from '../utility/firebaseAdminFunctions';
import { retrieveStudents_Promise } from '../utility/firebaseFunctions';
import { getStudentName } from '../utility/lessonKeeperFunctions';

type ScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'AdminScreen'
>;

type ScreenRouteProp = RouteProp<RootStackParamList, 'AdminScreen'>;

type ScreenProps = {
  navigation: ScreenNavigationProp;
  route: ScreenRouteProp;
};

export function AdminScreen(props: ScreenProps) {
  const fbContext = React.useContext(FirebaseContext);

  const [generateRoleOfType, setGenerateRoleOfType] = React.useState<
    'instructor' | 'groupRoot' | 'admin'
  >('instructor');

  const [userNames, setUserNames] = React.useState<{ [key: string]: string }>(
    {}
  );

  const [showTransferModal, setShowTransferModal] = React.useState(false);

  const [accessTokens, setAccessTokens] = React.useState<
    AccessToken[] | undefined
  >(undefined);

  const [isLoadingAccessToken, setIsLoadingAccessToken] = React.useState(false);

  React.useEffect(() => {
    if (fbContext.login.userGroup.isAdmin) {
      getUserNames().catch((error) =>
        console.error('Error getting user names:', error)
      );
      getAccessTokens().catch((error) =>
        console.error('Error getting access tokens:', error)
      );
    }
  }, [fbContext.login.userGroup]);

  async function getUserNames() {
    const promises = (fbContext.login.userGroup?.usersToAdministrate ?? []).map(
      async (i) => {
        const name = await getUsersNameBasedOnInstructor(i.id);
        return {
          ...i,
          name,
        };
      }
    );
    const users = await Promise.all(promises);
    const newUsernames: { [key: string]: string } = {};
    users.forEach((i) => {
      if (i.name) {
        newUsernames[i.id] = i.name;
      }
    });
    setUserNames(newUsernames);
  }

  async function getAccessTokens() {
    if (
      fbContext.login.userGroup.group &&
      fbContext.login.userGroup.role == 'groupRoot'
    ) {
      setAccessTokens(
        await getGroupAccessTokens(fbContext.login.userGroup.group)
      );
    } else {
      setAccessTokens([]);
    }
  }

  if (!fbContext.login.userGroup.isAdmin) {
    return <Text>Not admin</Text>;
  }

  function isCurrentUser(user: string) {
    return fbContext.login.selectedUserID === user;
  }

  function getUsersName(id: string) {
    if (userNames[id]) {
      return userNames[id] + ` (${id})`;
    }
    return id;
  }

  const transferSection = () => (
    <LKButton
      title="Transfer a student"
      onPress={() => setShowTransferModal(true)}
    />
  );

  const accessTokensSection = () => {
    if (fbContext.login.userGroup.role != 'groupRoot') {
      return null;
    }
    if (!accessTokens) {
      return (
        <ActivityIndicator
          size="large"
          animating={true}
          style={AppStyles.defaultMarginBottom}
        />
      );
    }

    const sortedTokens = accessTokens.sort(
      (a, b) => a.createdDate.seconds - b.createdDate.seconds
    );

    const redeemedCodes = sortedTokens.filter((i) => i.redeemed);

    const nonRedeemedCodes = sortedTokens.filter((i) => !i.redeemed);

    return (
      <>
        <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
          Access codes:
        </Text>
        {redeemedCodes ? (
          <>
            <Text style={[AppStyles.h3, AppStyles.defaultMarginBottom]}>
              Redeemed:
            </Text>
            {redeemedCodes.map((i) => (
              <AccessTokenDisplay
                key={i.id}
                token={i}
                usersName={getUsersName(i.associatedUserID ?? '')}
                reloadTokensCallback={getAccessTokens}
                reloadUsersCallback={getUserNames}
              />
            ))}
          </>
        ) : null}

        {nonRedeemedCodes.length ? (
          <>
            <Text style={[AppStyles.h3, AppStyles.defaultMarginBottom]}>
              Not redeemed:
            </Text>
            {nonRedeemedCodes.map((i) => (
              <AccessTokenDisplay
                key={i.id}
                token={i}
                usersName={getUsersName(i.associatedUserID ?? '')}
                reloadTokensCallback={getAccessTokens}
                reloadUsersCallback={getUserNames}
              />
            ))}
          </>
        ) : null}
      </>
    );
  };

  const transferModal = (
    <EnhancedModal
      isVisible={showTransferModal}
      onBackdropPress={() => {
        setShowTransferModal(false);
      }}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.2}
    >
      <View style={[AppStyles.basicModal, AppStyles.modalPadding]}>
        <TransferComponent userNames={userNames} />
      </View>
    </EnhancedModal>
  );

  const userList = () => {
    return (fbContext.login.userGroup.usersToAdministrate ?? []).sort(
      (a, b) => {
        const aName = getUsersName(a.id);
        const bName = getUsersName(b.id);

        const aTokens = aName.split(' ');
        const bTokens = bName.split(' ');

        const aToken = aTokens.length == 3 ? aTokens[1] : aTokens[0];
        const bToken = bTokens.length == 3 ? bTokens[1] : bTokens[0];

        return aToken.localeCompare(bToken);
      }
    );
  };

  return (
    <View style={AppStyles.container}>
      {transferModal}
      <ScrollView contentContainerStyle={AppStyles.screenScroller}>
        <Text style={[AppStyles.h2, { marginBottom: 20 }]}>Choose a user:</Text>
        {userList().map((user) => (
          <View key={user.id} style={AppStyles.adminInterfaceUser}>
            <TouchableOpacity
              onPress={() => {
                fbContext.actions.selectUserID(user.id);
              }}
              style={{ backgroundColor: 'clear' }}
            >
              <Text
                style={[isCurrentUser(user.id) ? { fontWeight: 'bold' } : {}]}
              >
                {getUsersName(user.id)}
              </Text>
              <Text>Role: {user.role}</Text>
            </TouchableOpacity>
          </View>
        ))}

        <View style={AppStyles.separator} />

        {transferSection()}

        <View style={AppStyles.separator} />

        {accessTokensSection()}

        {fbContext.login.userGroup.role === 'groupRoot' ? (
          <View style={[AppStyles.row]}>
            <LKButton
              disabled={isLoadingAccessToken}
              onPress={() => {
                const group = fbContext.login.userGroup.group;
                if (!group) {
                  return;
                }
                setIsLoadingAccessToken(true);
                generateAccessCode(group, generateRoleOfType)
                  .then(() => {
                    setIsLoadingAccessToken(false);
                    getAccessTokens().catch((error) =>
                      console.error('Error getting access tokens:', error)
                    );
                  })
                  .catch((error) =>
                    console.error('Error generating access code')
                  );
              }}
              title="Generate access code"
              style={[AppStyles.defaultMarginRight, { flex: 4 }]}
            />

            <Picker
              selectedValue={generateRoleOfType}
              style={[AppStyles.defaultMarginRight, { flex: 1 }]}
              onValueChange={(itemValue, itemIndex) =>
                setGenerateRoleOfType(itemValue)
              }
            >
              <Picker.Item label="Instructor" value="instructor" />
              <Picker.Item label="Admin" value="admin" />
              <Picker.Item label="Root" value="groupRoot" />
            </Picker>

            {isLoadingAccessToken ? (
              <ActivityIndicator size="small" animating={true} />
            ) : null}
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const AccessTokenDisplay: React.FunctionComponent<{
  token: AccessToken;
  usersName?: string;
  reloadTokensCallback: () => void;
  reloadUsersCallback: () => void;
}> = (props) => {
  const { token, usersName } = props;
  const accessLink = `https://web.lessonkeeper.app/main?accessCode=${token.id}&groupID=${token.groupID}`;
  const textInputRef = React.useRef<TextInput>(null);

  const [loading, setLoading] = React.useState(false);

  return (
    <View
      style={[AppStyles.accessToken, { backgroundColor: 'clear' }]}
      key={token.id}
    >
      {token.redeemed && token.redeemedDate && token.associatedUserID ? (
        <>
          <Text style={{ fontWeight: 'bold' }}>User: {usersName}</Text>
          <Text>
            Redeemed on: {token.redeemedDate.toDate().toLocaleString()}
          </Text>
        </>
      ) : null}
      <Text style={{ fontWeight: token.redeemed ? 'normal' : 'bold' }}>
        ID: {token.id}
      </Text>
      <Text style={{}}>Group: {token.groupID}</Text>
      <Text>Role: {token.userRole}</Text>
      <Text>Created: {token.createdDate.toDate().toLocaleString()}</Text>
      {token.redeemed ? (
        <LKButton
          style={{ width: 150 }}
          title="Revoke access"
          destructive={true}
          onPress={async () => {
            if (!token.associatedUserID) {
              return;
            }
            if (confirm("Do you really want to revoke this user's access?")) {
              setLoading(true);
              await revokeAccessCode(
                token.groupID,
                token.id,
                token.associatedUserID
              );
              setLoading(false);
              props.reloadTokensCallback();
              props.reloadUsersCallback();
            }
          }}
        />
      ) : null}
      {!token.redeemed ? (
        <View style={AppStyles.row}>
          <LKButton
            style={{ width: 150 }}
            title="Copy access link"
            onPress={() => {
              textInputRef.current?.focus();
              setTimeout(() => {
                if (document.execCommand('copy')) {
                  alert('The link has been copied to your clipboard');
                }
              }, 50);
            }}
          />
          <TextInput
            selectTextOnFocus={true}
            style={{ width: 1 }}
            value={accessLink}
            ref={textInputRef}
          />
          <LKButton
            style={{ width: 150 }}
            title="Delete token"
            destructive={true}
            onPress={async () => {
              if (confirm('Are you sure you want to delete this code?')) {
                setLoading(true);
                await deleteAccessCode(token.groupID, token.id);
                setLoading(false);
                props.reloadTokensCallback();
              }
            }}
          />
        </View>
      ) : null}
      {loading ? (
        <ActivityIndicator
          size="small"
          animating={true}
          style={AppStyles.defaultMarginTop}
        />
      ) : null}
    </View>
  );
};

const TransferComponent: React.FC<{ userNames: { [key: string]: string } }> = (
  props
) => {
  const [originalInstructorID, setOriginalInstructorID] = React.useState<
    string | undefined
  >();
  const [newInstructorID, setNewInstructorID] = React.useState<
    string | undefined
  >();
  const [studentToTransferID, setStudentToTransferID] = React.useState<
    string | undefined
  >();
  const [mergeStudent, setMergeStudent] = React.useState<boolean>(false);
  const [mergeIntoStudentID, setMergeIntoStudentID] = React.useState<
    string | undefined
  >();
  const [deleteRecords, setDeleteRecords] = React.useState(false);

  const [studentsFromOriginalInstructor, setStudentsFromOriginalInstructor] =
    React.useState<Array<Student>>([]);
  const [studentsFromNewInstructor, setStudentsFromNewInstructor] =
    React.useState<Array<Student>>([]);

  const [transferInProcess, setTransferInProcess] = React.useState(false);

  React.useEffect(() => {
    if (!originalInstructorID) {
      setStudentsFromOriginalInstructor([]);
      return;
    }
    retrieveStudents_Promise(originalInstructorID)
      .then((students) => setStudentsFromOriginalInstructor(students))
      .catch((error) => console.log(error));
  }, [originalInstructorID]);

  React.useEffect(() => {
    if (!newInstructorID) {
      setStudentsFromNewInstructor([]);
      return;
    }
    retrieveStudents_Promise(newInstructorID)
      .then((students) => setStudentsFromNewInstructor(students))
      .catch((error) => console.log(error));
  }, [newInstructorID]);

  const canSubmit = () => {
    if (!originalInstructorID || !newInstructorID) {
      return false;
    }
    if (!studentToTransferID) {
      return false;
    }
    if (mergeStudent && !mergeIntoStudentID) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Text style={[AppStyles.h2, AppStyles.defaultMarginBottom]}>
        Transfer student
      </Text>

      <View
        style={[
          AppStyles.row,
          AppStyles.defaultMarginBottom,
          { justifyContent: 'space-between' },
        ]}
      >
        <Text>Original instructor</Text>
        <Picker
          selectedValue={originalInstructorID}
          style={[AppStyles.defaultMarginRight, { flex: 1, maxWidth: 300 }]}
          onValueChange={(itemValue, itemIndex) =>
            setOriginalInstructorID(itemValue)
          }
        >
          <Picker.Item label="(None)" value={undefined} />
          {Object.keys(props.userNames).map((userID) => (
            <Picker.Item
              key={userID}
              label={`${props.userNames[userID]} (${userID})`}
              value={userID}
            />
          ))}
        </Picker>
      </View>

      {originalInstructorID ? (
        <View
          style={[
            AppStyles.row,
            AppStyles.defaultMarginBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <Text>Destination instructor</Text>
          <Picker
            selectedValue={newInstructorID}
            style={[AppStyles.defaultMarginRight, { flex: 1, maxWidth: 300 }]}
            onValueChange={(itemValue, itemIndex) =>
              setNewInstructorID(itemValue)
            }
          >
            <Picker.Item label="(None)" value={undefined} />
            {Object.keys(props.userNames)
              .filter((i) => i != originalInstructorID)
              .map((userID) => (
                <Picker.Item
                  key={userID}
                  label={`${props.userNames[userID]} (${userID})`}
                  value={userID}
                />
              ))}
          </Picker>
        </View>
      ) : null}

      <View
        style={[
          AppStyles.row,
          AppStyles.defaultMarginBottom,
          { justifyContent: 'space-between' },
        ]}
      >
        <Text>Student to transfer</Text>
        <Picker
          selectedValue={studentToTransferID}
          style={[AppStyles.defaultMarginRight, { flex: 1, maxWidth: 300 }]}
          onValueChange={(itemValue, itemIndex) =>
            setStudentToTransferID(itemValue)
          }
        >
          <Picker.Item label="(None)" value={undefined} />
          {studentsFromOriginalInstructor.map((student) => (
            <Picker.Item
              key={student.id}
              label={getStudentName(student)}
              value={student.id}
            />
          ))}
        </Picker>
      </View>

      <View
        style={[
          AppStyles.row,
          AppStyles.defaultMarginBottom,
          { justifyContent: 'space-between' },
        ]}
      >
        <Text>Merge student</Text>
        <Switch
          value={mergeStudent}
          onValueChange={(val) => setMergeStudent(val)}
        />
      </View>

      {mergeStudent ? (
        <View
          style={[
            AppStyles.row,
            AppStyles.defaultMarginBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <Text>Merge into student</Text>
          <Picker
            selectedValue={mergeIntoStudentID}
            style={[AppStyles.defaultMarginRight, { flex: 1, maxWidth: 300 }]}
            onValueChange={(itemValue, itemIndex) =>
              setMergeIntoStudentID(itemValue)
            }
          >
            <Picker.Item label="(None)" value={undefined} />
            {studentsFromNewInstructor.map((student) => (
              <Picker.Item
                key={student.id}
                label={getStudentName(student)}
                value={student.id}
              />
            ))}
          </Picker>
        </View>
      ) : null}

      <View
        style={[
          AppStyles.row,
          AppStyles.defaultMarginBottom,
          { justifyContent: 'space-between' },
        ]}
      >
        <Text>Remove original records</Text>
        <Switch value={deleteRecords} onValueChange={setDeleteRecords} />
      </View>

      <View style={AppStyles.separator} />

      <View style={AppStyles.row}>
        {transferInProcess ? (
          <ActivityIndicator
            size="small"
            animating={true}
            style={AppStyles.defaultMarginTop}
          />
        ) : null}
        <LKButton
          title={`Transfer student`}
          iconName="ios-arrow-forward"
          disabled={!canSubmit()}
          onPress={() => {
            if (
              !originalInstructorID ||
              !newInstructorID ||
              !studentToTransferID
            ) {
              return;
            }
            if (
              window.confirm(
                'Transfer this student? (If you continue, please do not close or refresh the page before you get altered that the transfer has completed.)'
              )
            ) {
              setTransferInProcess(true);
              transferStudent(
                originalInstructorID,
                studentToTransferID,
                newInstructorID,
                mergeStudent ? mergeIntoStudentID : undefined,
                deleteRecords
              )
                .then(() => {
                  setTransferInProcess(false);
                  alert('The transfer has been completed.');
                })
                .catch((error) =>
                  console.log('Error transfering student:', error)
                );
            }
          }}
        />
      </View>
    </>
  );
};
