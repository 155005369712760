import { StyleSheet } from 'react-native';

export const AppStyles = StyleSheet.create({
  //layout
  container: {
    flex: 1,
    backgroundColor: '#f0f2f5',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'clear',
  },
  blockMenuRow: {
    padding: 4,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
  },

  //margins
  defaultMarginRight: {
    marginRight: 6,
  },
  defaultMarginBottom: {
    marginBottom: 6,
  },
  defaultMarginTop: {
    marginTop: 6,
  },
  defaultMarginLeft: {
    marginLeft: 6,
  },

  //containers
  blockButtons: {
    position: 'absolute',
    right: 4,
    zIndex: 999,
  },
  blockMenu: {
    borderWidth: 1,
    alignSelf: 'flex-end',
    padding: 4,
  },
  addBlockMenu: {
    borderWidth: 1,
    bottom: 0,
    padding: 6,
  },
  blockLibraryItem: {
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
  },
  screenScroller: {
    //alignItems: 'center',
    padding: 6,
    flex: 1,
  },
  mainPageScroller: {
    padding: 16,
  },
  centerContent: {
    alignItems: 'center',
  },
  mainScreenRoadblock: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    minHeight: 200,
    flexShrink: 1,
    borderColor: 'lightgray',
    borderWidth: 1,
    padding: 10,
  },
  basicModal: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: 'darkgray',
    maxHeight: '100%',
  },
  modalPadding: {
    padding: 8,
  },
  loginModal: {
    alignItems: 'center',
  },

  //inputs
  basicTextInput: {
    borderWidth: 1,
    borderColor: 'lightgray',
    backgroundColor: 'white',
    flex: 1,
    padding: 4,
    borderRadius: 4,
  },
  multiLineTextInput: {
    height: 200,
    flexBasis: 'auto',
    marginBottom: 6,
    padding: 4,
  },
  lkButton: {
    backgroundColor: 'rgb(33, 150, 243)',
    borderRadius: 2,
    marginBottom: 4,
    padding: 6,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  //components
  lessonScroller: {
    padding: 40,
  },
  blockWrapper: {
    backgroundColor: 'clear',
    /* includes the responses, reply button, etc */ marginBottom: 10,
  },
  block: {
    borderWidth: 1,
    borderColor: 'lightgray',
    minHeight: 40,
    marginBottom: 10,
    padding: 6,
    borderRadius: 6,
  },
  dimmedBlock: {
    opacity: 0.5,
  },
  emptyBlockSign: {
    alignSelf: 'center',
  },
  emptyBlockText: {
    fontSize: 34,
    color: 'lightgray',
  },
  response: {
    padding: 8,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 10,
    borderColor: 'lightgray',
  },
  replyButton: {
    backgroundColor: 'lightgray',
    padding: 6,
    alignSelf: 'flex-end',
  },
  caption: {
    alignSelf: 'center',
  },
  listEditorCheckbox: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCheckbox: {
    minWidth: 24,
    minHeight: 24,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  listBullet: {
    width: 8,
    height: 8,
    marginRight: 8,
    marginBottom: 4,
    marginTop: 4,
    backgroundColor: 'darkgray',
    borderRadius: 4,
  },
  dueDate: {
    color: 'red',
    fontWeight: 'bold',
  },
  currentLessonLabel: {
    color: 'red',
    fontWeight: 'bold',
  },
  mainPageStudentHeader: {
    marginBottom: 40,
    backgroundColor: 'clear',
  },
  inlineStudentView: {
    marginBottom: 20,
    borderWidth: 1,
    shadowRadius: 6,
    shadowColor: 'lightgray',
    borderColor: 'lightgray',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 20,
    borderRadius: 10,
  },
  lessonCell: {
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  lessonCellBullet: {
    backgroundColor: 'lightgray',
    width: 10,
    height: 10,
    marginRight: 10,
    borderRadius: 5,
  },
  signInServiceButton: {
    width: 200,
    height: 46,
  },
  accessToken: {
    marginBottom: 20,
    alignItems: 'flex-start',
    backgroundColor: 'clear',
  },
  adminInterfaceUser: {
    marginBottom: 20,
    backgroundColor: 'clear',
  },

  //modal
  modal: {
    borderWidth: 2,
    borderColor: 'lightgray',
    shadowRadius: 4,
    padding: 6,
    position: 'absolute',
    top: 60,
    bottom: 60,
    left: 60,
    right: 60,
    zIndex: 9999,
  },

  //visual elements
  separator: {
    height: 1,
    backgroundColor: 'lightgray',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 4,
    marginRight: 4,
  },
  previousLessonLeftBar: {
    width: 10,
    backgroundColor: 'lightgray',
    marginRight: 6,
  },

  //text
  h1: {
    fontSize: 30,
    fontWeight: '400',
  },
  h2: {
    fontSize: 20,
    fontWeight: '400',
  },
  h3: {
    fontSize: 18,
    fontWeight: '400',
  },
  link: {
    color: 'blue',
  },
  secondaryText: {
    color: 'gray',
  },
  captionText: {
    fontSize: 14,
  },

  //debug
  bordered: { borderWidth: 1, borderColor: 'black' },
});
