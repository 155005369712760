import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Button, TextInput } from 'react-native';
import { FirebaseContext } from '../../../AppState';
import { HTMLInputComponent, HTMLInputTypes } from '../HTMLInputComponent';
import { ImageBlockView } from '../blocks/ImageBlockView';
import { LessonBlock, ResourceType } from '../../types/Types';
import { Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native';
import {
  getFBDownloadURL,
  deleteServerFile,
  getFirebaseUploadRef,
  uploadFileToLesson,
  UploadState,
} from '../../utility/firebaseFunctions';

export const ImageBlockEditorView: React.FunctionComponent<{
  block: LessonBlock;
  lessonID: string;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const [block, setBlock] = React.useState<LessonBlock>(props.block);
  const [imageDownloadURL, setImageDownloadURL] = React.useState<
    string | undefined
  >();

  const fbContext = React.useContext(FirebaseContext);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const selectedUserID = fbContext.login.selectedUserID;
  if (!selectedUserID) {
    return null;
  }

  React.useEffect(() => {
    let path = block.resource?.documentStorageParams?.path;

    if (path) {
      // console.log('looking up path for:');
      // console.log(props.block.resource);

      getFBDownloadURL(selectedUserID, props.lessonID, path, false, (url) =>
        setImageDownloadURL(url)
      );
    }
  }, [block]);

  const addFileFunction = (file: File) => {
    const fileExt = file.name.split('.').pop();
    const filename = `${block.id}.${fileExt}`;

    if (!fbContext.login.selectedUserID) {
      return;
    }

    uploadFileToLesson(
      file,
      filename,
      fbContext.login.selectedUserID,
      props.lessonID,
      (progress) => {},
      (state) => {
        if (state === UploadState.finished) {
          //update the block
          let updatedBlock: LessonBlock = {
            ...block,
            resource: {
              base: ResourceType.documentStorage,
              documentStorageParams: { path: filename },
            },
          };
          setBlock(updatedBlock);
          props.commitBlockFunction(updatedBlock);
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  const fileInputChanged = async () => {
    console.log('Files:');
    console.log(inputRef?.current?.files);

    const chosenFile = inputRef?.current?.files?.item(0);

    if (chosenFile) {
      //delete what was there?
      if (block.resource?.documentStorageParams?.path) {
        await deleteServerFile(
          getFirebaseUploadRef(
            selectedUserID,
            props.lessonID,
            block.resource?.documentStorageParams?.path
          )
        );
      }

      addFileFunction(chosenFile);
    }
  };

  return (
    <View>
      <Text>Caption:</Text>
      <TextInput
        value={block?.textContent ?? ''}
        onChangeText={(newText) => {
          const newBlock = { ...block, textContent: newText };
          setBlock(newBlock);
        }}
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
      />
      {imageDownloadURL && <ImageBlockView url={imageDownloadURL} text={''} />}

      {/* <TouchableOpacity
        onPress={() => {
          inputRef?.current?.click();
        }}
      >
        <Text>File Upload</Text>
        <HTMLInputComponent
          callbackRef={inputRef}
          onChange={fileInputChanged}
          type={HTMLInputTypes.image}
        />
      </TouchableOpacity> */}

      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(block);
          props.finish();
        }}
      />
    </View>
  );
};
