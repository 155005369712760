import { firebaseCurrentUser, firebaseObject } from './firebaseFunctions';

type AddGroupResponse = {
  systemError?: string;
  userError?: string;
  success: boolean;
};

type AddGroupToUserParams = {
  groupID: string;
  accessCode: string;
};

export async function addGroupToUserAndRedeemCode(
  groupID: string,
  accessCode: string
): Promise<AddGroupResponse> {
  const addGroupToUser = firebaseObject()
    .functions()
    .httpsCallable('addGroupToUser');
  const params: AddGroupToUserParams = { groupID, accessCode };

  try {
    const resultData = (await addGroupToUser(params)).data as AddGroupResponse;
    console.log('Got response:');
    console.log(resultData);
    return resultData;
  } catch (error) {
    return { systemError: `Problem connecting: ${error}`, success: false };
  }
}

export async function getFirebaseUserGroup() {
  const uid = firebaseCurrentUser();

  const tokenResult = await firebaseObject().auth().currentUser?.getIdTokenResult();
  if (!tokenResult?.claims["group"] && !tokenResult?.claims["role"]) {
    console.warn("No claim", tokenResult);
    return undefined;
  }
  console.warn("Claim token!");
  console.log(tokenResult);
  return {
    group: tokenResult?.claims["group"] as string,
    role: tokenResult?.claims["role"] as string,
  };

}
