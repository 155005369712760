import * as React from 'react';

export enum HTMLInputTypes {
  image,
  allMedia,
  audio,
  pdf,
}

export const HTMLInputComponent = (props: {
  onChange: () => void;
  callbackRef: React.MutableRefObject<HTMLInputElement | null>;
  type: HTMLInputTypes;
}) => (
  <input
    type="file"
    onChange={() => {
      const file = props.callbackRef?.current?.files?.item(0);
      if (file && file.size > 1024 * 1024 * 5) {
        alert("I'm sorry, but this file is beyond the upload size limit");
        return;
      }
      props.onChange();
    }}
    ref={props.callbackRef}
    style={{ display: 'none' }}
    accept={typeToExtensions(props.type)
      .map((i) => `.${i}`)
      .join(',')}
  />
);

function typeToExtensions(input: HTMLInputTypes) {
  switch (input) {
    case HTMLInputTypes.allMedia:
      return ['pdf', 'mp3', 'm4a', 'png', 'jpg', 'jpeg', 'gif'];
    case HTMLInputTypes.image:
      return ['png', 'jpg', 'jpeg', 'gif'];
    case HTMLInputTypes.audio:
      return ['mp3', 'm4a'];
    case HTMLInputTypes.pdf:
      return ['pdf'];
    default:
      return [];
  }
}
