import * as React from 'react';
import { AppStyles } from '../../style/Styles';
import { Image } from 'react-native';
import { LessonBlock } from '../../types/Types';
import { Text, View } from '../Themed';

export const ImageBlockView: React.FunctionComponent<{
  url: string;
  text: string;
}> = (props) => {
  return (
    <View>
      <img
        src={props.url}
        style={{ alignSelf: 'center', maxWidth: '80%', maxHeight: '600px' }}
      />
      <Text style={AppStyles.caption}>{props.text}</Text>
    </View>
  );
};
