import {
  BlockType,
  Instructor,
  LessonBlock,
  LessonBlockList,
  LessonBlockListItem,
  LessonModel,
  Student
} from "../types/Types";

const addTypes: { type: BlockType; title: string }[] = [
  { type: BlockType.text, title: "Text" },
  { type: BlockType.heading, title: "Headline" },
  { type: BlockType.list, title: "List" },
  { type: BlockType.link, title: "Link" },
  { type: BlockType.youtube, title: "YouTube" },
  { type: BlockType.divider, title: "Divider" },
  { type: BlockType.instructorNote, title: "Instructor note" },
];

export function getBlockTypeTitle(block: LessonBlock) {
  switch (block.type) {
    case BlockType.heading:
      return "Headline";
    case BlockType.text:
      return "Text";
    case BlockType.instructorNote:
      return "Instructor note";
    case BlockType.list:
      return "List";
    case BlockType.youtube:
      return "YouTube";
    case BlockType.image:
      return "Image";
    case BlockType.audio:
      return "Audio";
    case BlockType.link:
      return "Link";
    case BlockType.pdf:
      return "PDF";
    case BlockType.notation:
      return "Notation";
    case BlockType.drawing:
      return "Drawing";
    case BlockType.divider:
      return "Divider";
  }
}

export function getBlockThumbnail(block: LessonBlock) {
  switch (block.type) {
    case BlockType.heading:
      return block.headline ?? "";
    case BlockType.text:
      return block.headline || block.textContent;
    case BlockType.instructorNote:
      return block.textContent;
    case BlockType.list:
      return (block.listItems ?? [])
        .map((i) => i.title)
        .filter((i) => i.length)
        .join(", ");
    case BlockType.youtube:
      return block.textContent;
    case BlockType.image:
      return block.textContent;
    case BlockType.audio:
      return block.textContent;
    case BlockType.link:
      return block.textContent || block.link;
    case BlockType.pdf:
    case BlockType.notation:
    case BlockType.drawing:
      return block.textContent;
  }
}

export function getCurrentInstructor(instructors: Instructor[]) {
  //we want the one with the greatest, so switch it around => b - a
  return instructors.sort((a, b) => b.modifiedDate - a.modifiedDate)[0];
}

export function getLessonDateTime(lesson: LessonModel) {
  let lessonDate = new Date(lesson.lessonMeta.lessonDate * 1000);
  lessonDate.setSeconds(0);
  return lessonDate;
}

export function getLessonName(lesson: LessonModel, students: Student[]) {
  if (lesson.lessonName) {
    return lesson.lessonName;
  }
  if (students.length) {
    const studentNames = students.map((i) => getStudentName(i)).join(", ");
    return `${studentNames} - ${getLessonDateTimeString(lesson)}`;
  }
  return getLessonDateTimeString(lesson);
}

export function studentsForLesson(lesson: LessonModel, students: Student[]) {
  return students.filter(
    (i) => lesson.lessonMeta.studentIDs.indexOf(i.id) !== -1
  );
}

export function instructorForLesson(
  lesson: LessonModel,
  instructors: Instructor[]
) {
  const found = instructors.find(
    (i) => lesson.lessonMeta.instructorID === i.id
  );
  return found;
}

export function getStudentName(student: Student) {
  if (student.lastName) {
    return `${student.firstName} ${student.lastName}`;
  }
  return student.firstName;
}

export function modifiedDateToString(modifiedDate: number) {
  return new Date(modifiedDate * 1000).toLocaleString();
}

export function getLessonDateTimeString(lesson: LessonModel) {
  //console.log("Getting date from : " + lesson.lessonMeta.lessonDate)
  const date = new Date(lesson.lessonMeta.lessonDate * 1000);
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
}

export function getPDFForDrawing(lessonBlock: LessonBlock) {
  return `${lessonBlock.id}.pdf`;
}

export function getURLString(lessonBlock: LessonBlock) {
  const resource = lessonBlock.resource;
  // console.log("Get url for resource: ")
  // console.log(resource)
  const url = resource?.urlParams?.url;
  if (url) {
    return url;
  }
  return "";
}

export function getYouTubeID(url: string) {
  if (!url) {
    return false;
  }

  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export function getLinkText(
  lessonBlock: LessonBlock,
  defaultText: string = "LINK"
) {
  //return "Link"
  if (lessonBlock.textContent.length > 0) {
    return lessonBlock.textContent;
  }
  if (lessonBlock.link) {
    return lessonBlock.link;
  }
  return defaultText;
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .toUpperCase();
}

export function generateModifiedDate() {
  return new Date().getTime() / 1000.0;
}

export function blockTypeBasedOnFileExtension(
  extension: string
): BlockType | undefined {
  switch (extension) {
    case "pdf":
      return BlockType.pdf;
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
      return BlockType.image;
    case "mp3":
    case "m4a":
    case "wav":
      return BlockType.audio;
    default:
      return undefined;
  }
}

export function isEmptyBlock(block: LessonBlock) {
  switch (block.type) {
    case BlockType.heading:
    case BlockType.text:
      return !block.headline && !block.textContent;
    case BlockType.instructorNote:
      return !block.textContent;
    case BlockType.list:
      if (block.list) {
        return isListEmpty(block.list);
      }
      if (!block.headline) {
        return true;
      }
      if (!block.listItems) {
        return true;
      }
      if (!block.listItems.length) {
        return true;
      }
      if (!block.listItems.map((i) => i.title).join("").length) {
        return true;
      }
      return false;
    case BlockType.link:
      return !block.link;
    case BlockType.audio:
    case BlockType.image:
    case BlockType.pdf:
    case BlockType.notation:
    case BlockType.drawing:
      return !block.resource;

    case BlockType.youtube:
      return !block.link;
    case BlockType.divider:
      false;
  }
}

export function isListItemEmpty(listItem: LessonBlockListItem): boolean {
  if (listItem.title) {
    return false;
  }
  if (!listItem.list) {
    return true;
  }
  let result = listItem.list.children.reduce((acc, item) => {
    if (acc) {
      return acc;
    }
    if (!isListItemEmpty(item)) {
      return true;
    }
    return false;
  }, false);
  return result;
}

export function isListEmpty(list: LessonBlockList): boolean {
  let result = list.children.reduce((acc, item) => {
    if (acc) {
      return acc;
    }
    if (isListItemEmpty(item)) {
      return true;
    }
    return false;
  }, false);
  return result;
}
