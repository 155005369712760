import * as React from 'react';
import { Text, View } from '../Themed';

export const AudioView: React.FunctionComponent<{
  caption: string;
  audioURL: string;
}> = (props) => (
  <View style={{ alignItems: 'center' }}>
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSize: '11pt',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <span>{props.caption}</span>
      <audio controls>
        <source src={props.audioURL} />
      </audio>
      <a
        href={props.audioURL}
        style={{ fontFamily: '-apple-system', color: '#34b575' }}
        download
      >
        Download audio file
      </a>
    </div>
  </View>
);
