import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import {
  ActivityIndicator,
  StyleProp,
  TouchableOpacity,
  ViewStyle
} from 'react-native';
import { AppStyles } from '../../style/Styles';
import { Text } from '../Themed';

export const LKButton: React.FunctionComponent<{
  onPress: () => void;
  title: string;
  iconName?: string;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  destructive?: boolean;
  loading?: boolean;
}> = (props) => {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={() => {
        if (props.disabled) { return; }
        if (props.loading !== true) {
          props.onPress();
        }
      }}
      style={[
        AppStyles.lkButton,
        props.style,
        props.disabled || props.loading ? { backgroundColor: 'lightgray' } : {},
        props.destructive ? { backgroundColor: '#aa0000', opacity: 0.8 } : {},
      ]}
    >
      {props.iconName ? (
        <Ionicons
          name={props.iconName}
          size={20}
          color={'white'}
          style={AppStyles.defaultMarginRight}
        />
      ) : null}
      <Text style={{ color: 'white' }}>{props.title.toUpperCase()}</Text>
      {props.loading ? (
        <ActivityIndicator size="small" animating={true} />
      ) : null}
    </TouchableOpacity>
  );
};
