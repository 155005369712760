import * as React from 'react';

export function useKeyPress(key: string, callback: () => void) {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === key) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
