import * as React from "react";
import { AppStyles } from "../../style/Styles";
import { arrayMove } from "../../utility/general";
import { Button, TextInput, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import {
  LessonBlock,
  LessonBlockList,
  LessonBlockListItem,
  ListItem,
  ListType,
} from "../../types/Types";
import { LKButton } from "../basicComponents/LKButton";
import { Text, View } from "react-native";
import { uuidv4 } from "../../utility/lessonKeeperFunctions";

const ListBlockSublistEditorView: React.FC<{
  list: LessonBlockList;
  listBinding: (list: LessonBlockList) => void;
}> = (props) => {
  const listTypes: { type: ListType; title: string; active: boolean }[] = [
    {
      type: ListType.bullets,
      title: "Bullets",
      active:
        props.list.type == undefined || props.list.type === ListType.bullets,
    },
    {
      type: ListType.numbers,
      title: "Numbers",
      active: props.list.type === ListType.numbers,
    },
    {
      type: ListType.checkboxes,
      title: "Checkboxes",
      active: props.list.type === ListType.checkboxes,
    },
  ];

  return (
    <View>
      <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
        {listTypes.map((i, index) => (
          <TouchableOpacity
            key={i.title}
            style={[
              AppStyles.bordered,
              {
                flex: 1,
                backgroundColor: i.active ? "lightgray" : "clear",
                alignItems: "center",
              },
            ]}
            onPress={() => {
              const newList = { ...props.list, type: i.type };
              props.listBinding(newList);
            }}
          >
            <Text>{i.title}</Text>
          </TouchableOpacity>
        ))}
      </View>

      {props.list.children.map((child, index) => {
        return (
          <View key={child.id}>
            <EditableListItem
              listItem={child}
              index={index}
              listStyle={props.list.type}
              editListItem={(newItem) => {
                let updatedList = { ...props.list };
                updatedList.children[index] = newItem;
                props.listBinding(updatedList);
              }}
              canAddSubList={!child.list || !child.list.children}
              addSubList={() => {
                let updatedList = { ...props.list };
                updatedList.children[index].list = {
                  type: ListType.bullets,
                  children: [{ id: uuidv4(), title: "" }],
                };
                props.listBinding(updatedList);
              }}
              deleteListItem={() => {
                let updatedList = { ...props.list };
                updatedList.children = updatedList.children.filter(
                  (i) => i.id != child.id
                );
                props.listBinding(updatedList);
              }}
              moveListItem={(shift) => {
                let updatedList = { ...props.list };
                updatedList.children = arrayMove(
                  updatedList.children ?? [],
                  index,
                  index + shift
                );
                props.listBinding(updatedList);
              }}
              isLast={index == props.list.children.length - 1}
            />
            {child.list ? (
              <View style={{ marginLeft: 20, marginTop: 10 }}>
                <ListBlockSublistEditorView
                  list={child.list}
                  listBinding={(newValue) => {
                    let updatedList = { ...props.list };
                    updatedList.children[index].list = newValue;
                    props.listBinding(updatedList);
                  }}
                />
              </View>
            ) : null}
          </View>
        );
      })}

      <LKButton
        title="Add item"
        onPress={() => {
          const updatedList: LessonBlockList = {
            ...props.list,
            children: [...props.list.children, { id: uuidv4(), title: "" }],
          };
          props.listBinding(updatedList);
        }}
      />
    </View>
  );
};

// const ListBlockItemEditor: React.FC<{
//   item: LessonBlockListItem,

// }> = props => {

// }

export const ListBlockEditorView: React.FunctionComponent<{
  block: LessonBlock;
  commitBlockFunction: (block: LessonBlock) => void;
  finish: () => void;
}> = (props) => {
  const [block, setBlock] = React.useState<LessonBlock>(props.block);

  const convertedList: LessonBlockList = block.list ??
    convertList(block) ?? {
      type: ListType.bullets,
      children: [],
    };

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "space-between",
      }}
    >
      <Text style={[AppStyles.defaultMarginBottom]}>Headline:</Text>
      <TextInput
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginBottom]}
        value={block?.headline ?? ""}
        onChangeText={(newText) => {
          const newBlock = { ...block, headline: newText };
          setBlock(newBlock);
        }}
      />

      {/* <Text>List items: {JSON.stringify(block.listItems ?? [])}</Text> */}
      {convertedList ? (
        <ListBlockSublistEditorView
          list={convertedList}
          listBinding={(newList) => {
            const updatedBlock = { ...block, list: newList };
            setBlock(updatedBlock);
          }}
        />
      ) : null}
      {/* {block.listItems &&
        block.listItems.map((listItem, index) => {
          return (
            <EditableListItem
              key={`${listItem.title}${index}`}
              listItem={listItem}
              index={index}
              isLast={index === (block.listItems ?? []).length - 1}
              listStyle={block.listType ?? ListType.bullets}
              editListItem={(newItem) => {
                var newBlock = { ...block };
                (newBlock.listItems ?? [])[index] = newItem;
                setBlock(newBlock);
              }}
              deleteListItem={() => {
                setBlock({
                  ...block,
                  listItems: block.listItems?.filter((i) => i !== listItem),
                });
              }}
              moveListItem={(shift) => {
                var updatedBlock = { ...block };
                updatedBlock.listItems = arrayMove(
                  updatedBlock.listItems ?? [],
                  index,
                  index + shift
                );
                setBlock(updatedBlock);
              }}
            />
          );
        })}

      <LKButton
        title="Add item"
        onPress={() => {
          const updatedBlock = {
            ...block,
            listItems: [
              ...(block.listItems ?? []),
              {
                title: "",
                doable: block.listType == ListType.checkboxes,
                done: false,
              },
            ],
          };
          setBlock(updatedBlock);
        }}
      /> */}

      <Button
        title="Save"
        onPress={() => {
          props.commitBlockFunction(block);
          props.finish();
        }}
      />
    </View>
  );
};

export const EditableListItem: React.FunctionComponent<{
  listItem: LessonBlockListItem;
  index: number;
  listStyle: ListType;
  editListItem: (item: LessonBlockListItem) => void;
  deleteListItem: () => void;
  moveListItem: (shift: number) => void;
  canAddSubList: boolean;
  addSubList: () => void;
  isLast: boolean;
}> = (props) => {
  const [listItem, setListItem] = React.useState(props.listItem);

  return (
    <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
      {props.listStyle === ListType.bullets && (
        <View style={AppStyles.listBullet} />
      )}
      {props.listStyle === ListType.numbers && <Text>{props.index + 1}. </Text>}
      {props.listStyle === ListType.checkboxes && (
        <TouchableOpacity
          style={AppStyles.listEditorCheckbox}
          onPress={() =>
            props.editListItem({
              ...props.listItem,
              meta: { done: props.listItem.meta?.done ? false : true },
            })
          }
        >
          {props.listItem.meta?.done ? (
            <Ionicons name={"ios-checkbox-outline"} size={24} />
          ) : (
            <View style={AppStyles.emptyCheckbox} />
          )}
        </TouchableOpacity>
      )}
      <TextInput
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginRight]}
        value={listItem.title}
        onBlur={() => props.editListItem(listItem)}
        onChangeText={(newText) => {
          setListItem({ ...props.listItem, title: newText });
        }}
      />
      <TouchableOpacity
        onPress={() => props.moveListItem(-1)}
        disabled={props.index === 0}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-arrow-up"
          size={24}
          color={props.index === 0 ? "lightgray" : "black"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => props.moveListItem(1)}
        disabled={props.isLast}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-arrow-down"
          size={24}
          color={props.isLast ? "lightgray" : "black"}
        />
      </TouchableOpacity>
      {props.canAddSubList ? (
        <TouchableOpacity
          onPress={() => props.addSubList()}
          style={AppStyles.defaultMarginRight}
        >
          <Ionicons name="ios-list" size={24} color={"black"} />
        </TouchableOpacity>
      ) : null}
      <TouchableOpacity
        disabled={props.index === 0 && props.isLast}
        onPress={() => props.deleteListItem()}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-trash"
          size={24}
          color={props.index === 0 && props.isLast ? "lightgray" : "black"}
        />
      </TouchableOpacity>
    </View>
  );
};

export const OLD_EditableListItem: React.FunctionComponent<{
  listItem: ListItem;
  index: number;
  listStyle: ListType;
  editListItem: (item: ListItem) => void;
  deleteListItem: () => void;
  moveListItem: (shift: number) => void;
  isLast: boolean;
}> = (props) => {
  const [listItem, setListItem] = React.useState(props.listItem);

  return (
    <View style={[AppStyles.row, AppStyles.defaultMarginBottom]}>
      {props.listStyle === ListType.bullets && (
        <View style={AppStyles.listBullet} />
      )}
      {props.listStyle === ListType.numbers && <Text>{props.index + 1}. </Text>}
      {props.listStyle === ListType.checkboxes && (
        <TouchableOpacity
          style={AppStyles.listEditorCheckbox}
          onPress={() =>
            props.editListItem({
              ...props.listItem,
              done: props.listItem.done ? false : true,
            })
          }
        >
          {props.listItem.done ? (
            <Ionicons name={"ios-checkbox-outline"} size={24} />
          ) : (
            <View style={AppStyles.emptyCheckbox} />
          )}
        </TouchableOpacity>
      )}
      <TextInput
        style={[AppStyles.basicTextInput, AppStyles.defaultMarginRight]}
        value={listItem.title}
        onBlur={() => props.editListItem(listItem)}
        onChangeText={(newText) => {
          setListItem({ ...props.listItem, title: newText });
        }}
      />
      <TouchableOpacity
        onPress={() => props.moveListItem(-1)}
        disabled={props.index === 0}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-arrow-up"
          size={24}
          color={props.index === 0 ? "lightgray" : "black"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => props.moveListItem(1)}
        disabled={props.isLast}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-arrow-down"
          size={24}
          color={props.isLast ? "lightgray" : "black"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        disabled={props.index === 0 && props.isLast}
        onPress={() => props.deleteListItem()}
        style={AppStyles.defaultMarginRight}
      >
        <Ionicons
          name="ios-trash"
          size={24}
          color={props.index === 0 && props.isLast ? "lightgray" : "black"}
        />
      </TouchableOpacity>
    </View>
  );
};

function convertList(inputBlock: LessonBlock): LessonBlockList | undefined {
  if (!inputBlock.listItems) {
    return undefined;
  }

  return {
    type: inputBlock.listType ?? ListType.bullets,
    children: inputBlock.listItems.map((item) => ({
      id: uuidv4(),
      title: item.title,
      ...(item.done ? { meta: { done: true } } : {}), //only include the meta if it exists -- can't send an undefined property to Firebase
    })),
  };
}
